import axios, { endpoints } from 'src/utils/axios';

const Supplier = async (admin_id, storeid) => {
  try {
    const response = await axios.get(endpoints.supplier.suplierstore(admin_id, storeid));
    if (response.data.length > 0) {
      return response;
    }
    return [];
  } catch (error) {
    console.error('An error occurred', error);
    return [];
  }
};

export default Supplier;
