/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function CouTableToolbar({ filters, onFilters, onExport, roleOptions, dateError, t }) {
  const popover = usePopover();

  const handleFilterName = useCallback(
    (event) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterStartDate = useCallback(
    (newValue) => {
      const isValidDate = !isNaN(new Date(newValue).getTime());

      if (isValidDate) {
        onFilters('startDate', newValue);
      } else {
        // Handle invalid date input, e.g., show an error message or set an error state
        console.error('Invalid date entered for start date');
      }
    },
    [onFilters]
  );

  const handleFilterEndDate = useCallback(
    (newValue) => {
      const isValidDate = !isNaN(new Date(newValue).getTime());

      if (isValidDate) {
        onFilters('endDate', newValue);
      } else {
        // Handle invalid date input, e.g., show an error message or set an error state
        console.error('Invalid date entered for end date');
      }
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {/* <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Discount</InputLabel>

          <Select
            multiple
            value={filters.role}
            onChange={handleFilterRole}
            input={<OutlinedInput label="Discount" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {roleOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox disableRipple size="small" checked={filters.role.includes(option)} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          {t === 2 ? null : (
            <>
              <DatePicker
                label="From"
                value={filters.startDate}
                onChange={handleFilterStartDate}
                slotProps={{ textField: { fullWidth: true, error: dateError } }}
                sx={{
                  maxWidth: { md: 180 },
                }}
              />
              <DatePicker
                label="To"
                value={filters.endDate}
                onChange={handleFilterEndDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: dateError,
                  },
                }}
                sx={{
                  maxWidth: { md: 180 },
                }}
              />
            </>
          )}

          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search by code or name...."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <Tooltip title="Export" arrow>
            <IconButton onClick={onExport}>
              <Iconify icon="solar:import-bold" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 140 }}>
        {/* <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem> */}

        {/* <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}

CouTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  roleOptions: PropTypes.array,
  dateError: PropTypes.bool,
  t: PropTypes.object,
  onExport: PropTypes.func,
};
