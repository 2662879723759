/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { countries } from 'src/assets/data';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';

// import { paths } from 'src/routes/paths';
import Button from '@mui/material/Button';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import axios, { endpoints } from 'src/utils/axios';

import { useAuthContext } from 'src/auth/hooks';

// import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { usePopover } from 'src/components/custom-popover';
// import { RouterLink } from 'src/routes/components';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { LoadingScreen } from 'src/components/loading-screen';
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function AccountGeneral({ userdata }) {
  const { enqueueSnackbar } = useSnackbar();
  const password = useBoolean();
  const confirm = useBoolean();

  const { admin_id, store_id, tax_system, role_id, user } = userdata;
  const users = userdata;
  // console.log(userdata);
  const [storedata, setstoredata] = useState([]);
  const [loading, setloading] = useState(true);

  const { logout } = useAuthContext();
  const router = useRouter();

  const popover = usePopover();

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    photoURL: Yup.mixed().nullable().required('Avatar is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    country: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip code is required'),
    about: Yup.string().required('About is required'),
    tax: Yup.string().required('About is required'),
    taxname: Yup.string().required('About is required'),
    user_name: Yup.string(),
    password: Yup.string(),

    // not required
    isPublic: Yup.boolean(),
  });

  const defaultValues = {
    displayName: users?.storename || '',
    code: users?.storecode || '',

    email: users?.email || '',
    photoURL: users?.logo || null,
    phoneNumber: users?.phone_no || '',
    country: users?.country || '',
    address: users?.addres || '',
    state: users?.state || '',
    city: users?.city || '',
    zipCode: users?.zipCode || '',
    about: users?.about || '',
    isPublic: users?.isPublic || false,
    tax: users?.tax || '',
    taxname: users?.taxName || '',
    // user_name: storedata1[0]?.user_name || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      // enqueueSnackbar('Update success!');
      // console.info('DATA', data);
      // onClick={() => {
      // }}
      // popover.onClose();
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('photoURL', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );
  const getAllItems = async () => {
    try {
      const response = await axios.post(endpoints.store.storeprofile, { admin_id, store_id, tax_system, role_id, user });

      if (response.status === 200) {
        const responseData = response.data;
        const { store } = responseData;
        // console.log(store);
        setstoredata(store[0]?.subtaxData);
        // setstoredata1(store[0]?.loginData);
        // console.log(store[0]?.loginData[0]?.user_name);
        // console.log(store[0]?.loginData[0]?.password);

        // setValue('user_name', store[0]?.loginData[0]?.user_name);
        // setValue('password', store[0]?.loginData[0]?.password);
        setValue('country', store[0]?.countryData[0]?.countryname);
        setValue('state', store[0]?.stateData[0]?.statename);
        setloading(false);
        // reset(defaultValues);
      } else {
        console.error('Unexpected Response Status:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getAllItems();
  }, [admin_id, store_id, tax_system, role_id, user, userdata]);

  console.log(storedata);
  const handlePasswordChange = async () => {
    // Implement logic to change the password here
    // For example, you can make an API call to update the password
    handleLogout();
    // After successful password change, you can close the dialog
    confirm.onFalse();
  };

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      // router.replace('/');
      router.push(paths.auth.jwt.forgot);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid xs={12} md={4}>
              <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center' }}>
                <RHFUploadAvatar
                  name="photoURL"
                  disabled
                  inputProps={{ readOnly: true }}
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.disabled',
                      }}
                    >
                      {users?.storecode}
                      <br />
                    </Typography>
                  }
                />

                {/* <RHFSwitch name="isPublic" labelPlacement="start" label="Public Profile" sx={{ mt: 5 }} />

          <Button variant="soft" color="error" sx={{ mt: 3 }}>
            Delete User
          </Button> */}
              </Card>
            </Grid>

            <Grid xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <RHFTextField name="displayName" label="Name" inputProps={{ readOnly: true }} />
                  {/* <RHFTextField name="code" label="Code" inputProps={{ readOnly: true }} /> */}

                  <RHFTextField name="email" label="Email" inputProps={{ readOnly: true }} />
                  <RHFTextField name="phoneNumber" label="Contact" inputProps={{ readOnly: true }} />
                  <RHFTextField name="country" label="Country" inputProps={{ readOnly: true }} />
                  <RHFTextField name="state" label="State/Region" inputProps={{ readOnly: true }} />
                  {/* <RHFTextField name="city" label="City" /> */}
                  <RHFTextField name="address" label="Address" inputProps={{ readOnly: true }} />
                  <RHFTextField name="taxname" label="Tax Name" inputProps={{ readOnly: true }} />

                  <RHFTextField name="tax" label="Tax(%)" inputProps={{ readOnly: true }} />
                  {/* <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                    }}
                  > */}
                  {storedata.map((tax, index) => (
                    <>
                      <RHFTextField
                        name={`s_taxname_${index}`}
                        label={`Sub Tax${index + 1}(Name)`}
                        inputProps={{ readOnly: true }}
                        value={tax.name || ''}
                        sx={{ textAlign: 'right' }}
                      />
                      <RHFTextField
                        name={`s_tax_${index}`}
                        label={`Sub Tax ${index + 1} (%) `}
                        inputProps={{ readOnly: true }}
                        value={tax.tax || ''}
                        sx={{ textAlign: 'right' }}
                      />
                    </>
                  ))}
                  {/* </Box> */}
                  {/* <RHFTextField
                    name="user_name"
                    label="User Name"
                    inputProps={{ readOnly: true }}
                    style={{ pointerEvents: 'none', color: 'inherit', backgroundColor: 'transparent', border: 'none' }}
                  /> */}
                  {/* <RHFTextField name="user_name" label="User Name" disabled /> */}
                  {/* <RHFTextField
                    name="password"
                    type={password.value ? 'text' : 'password'}
                    label="Password"
                    InputProps={{
                      // shrink: true,
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  {/* <RHFAutocomplete
              name="country"
              label="Country"
              options={countries.map((country) => country.label)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => {
                const { code, label, phone } = countries.filter((country) => country.label === option)[0];

                if (!label) {
                  return null;
                }

                return (
                  <li {...props} key={label}>
                    <Iconify key={label} icon={`circle-flags:${code.toLowerCase()}`} width={28} sx={{ mr: 1 }} />
                    {label} ({code}) +{phone}
                  </li>
                );
              }}
            /> */}

                  {/* <RHFTextField name="state" label="State/Region" />
            <RHFTextField name="city" label="City" />
            <RHFTextField name="zipCode" label="Zip/Code" /> */}
                </Box>

                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  {/* <RHFTextField name="about" multiline rows={4} label="About" /> */}

                  <LoadingButton type="submit" onClick={confirm.onTrue}>
                    Change password?
                  </LoadingButton>
                  {/* <Link component={RouterLink} href={paths.auth.jwt.forgot} variant="subtitle2">
                    Forgot password?
                  </Link> */}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Logout"
        content="Are you sure you want to logout?"
        action={
          <Button variant="contained" color="error" onClick={handlePasswordChange}>
            Logout
          </Button>
        }
      />
    </>
  );
}
