import { Outlet, Navigate } from 'react-router-dom';
import { lazy, Suspense, useState, useEffect } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard2';
import OverviewAppPage from 'src/pages/dashboard/dash/app';
import AccountPagestore from 'src/pages/dashboard/storeaccount/account';

import { LoadingScreen } from 'src/components/loading-screen';

import { OrderListView } from 'src/sections/order/view';
import { Contextid } from 'src/sections/customers/contex';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/stdash/booking'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// PRODUCT master oct 16
const ProductDetails = lazy(() => import('src/pages/dashboard/productmaster/details'));
const ProductEdit = lazy(() => import('src/pages/dashboard/productmaster/edit'));
const SubcatList = lazy(() => import('src/pages/dashboard/productmaster/subcat'));
const UnitList = lazy(() => import('src/pages/dashboard/productmaster/unit'));
const CategoryList = lazy(() => import('src/pages/dashboard/productmaster/category'));
const TaxList = lazy(() => import('src/pages/dashboard/productmaster/tax'));

// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/cprofile/profile'));

// const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
// const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
// const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
// const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
// const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// supplier
const SupplierCreatePage = lazy(() => import('src/pages/dashboard/supplier/new'));
const SupplierListView = lazy(() => import('src/pages/dashboard/supplier/view'));
// const SupplierProductOrder = lazy(() => import('src/pages/dashboard/supplier/order'));
const SupplierProfilePage = lazy(() => import('src/pages/dashboard/supplier/supplierprofile'));

// purchase
const PurchaseCreate = lazy(() => import('src/pages/dashboard/purchase/new'));
const PurchaseListPage = lazy(() => import('src/pages/dashboard/purchase/list'));
const PurchaseEditPage = lazy(() => import('src/pages/dashboard/purchase/edit'));
const GrnListPage = lazy(() => import('src/pages/dashboard/purchase/grnlist'));
const GrnViewPage = lazy(() => import('src/pages/dashboard/purchase/grnview'));
const PostedGrnListPage = lazy(() => import('src/pages/dashboard/purchase/postedgrn'));
const GrnToQualityPage = lazy(() => import('src/pages/dashboard/purchase/grntoquality'));
const ReturnListPage = lazy(() => import('src/pages/dashboard/purchase/returnlist'));
const RtnGrnViewPage = lazy(() => import('src/pages/dashboard/purchase/returngrnview'));
const PurchaseInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/purchase/details'));
const SupplierPoInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/purchase/supplierPoDetails'));

// ----------------------------------------------------------------------
// invoice credits
const InvoiceCreditPage = lazy(() => import('src/sections/invoice-credits/invoice-credits-create'));

const CusList = lazy(() => import('src/pages/dashboard/customers/master')); // oct 17
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customers/new'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customers/edit'));
// const TypeEditPage = lazy(() => import('src/pages/dashboard/customers/type/edit'));
const TypeList = lazy(() => import('src/pages/dashboard/customers/type/master'));
const TypeCreatePage = lazy(() => import('src/pages/dashboard/customers/type/new'));

const DisList = lazy(() => import('src/pages/dashboard/customers/discount/master'));
const DisCreatePage = lazy(() => import('src/pages/dashboard/customers/discount/new'));
const GiftList = lazy(() => import('src/pages/dashboard/customers/gift/master'));
const CouList = lazy(() => import('src/pages/dashboard/sales/gift/master'));

const SalesInvoiceListPage = lazy(() => import('src/pages/dashboard/sales/list'));
const SalesInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/sales/details'));
const SalesInvoiceCreatePage = lazy(() => import('src/pages/dashboard/sales/new'));
const SalesInvoiceEditPage = lazy(() => import('src/pages/dashboard/sales/edit'));
const Salesret = lazy(() => import('src/pages/dashboard/sales/return'));

const ProductList = lazy(() => import('src/pages/dashboard/inventory/list'));
const ProductCreate = lazy(() => import('src/pages/dashboard/inventory/new'));
const ProductListall = lazy(() => import('src/pages/dashboard/inventory/listall'));

const ProductMaster = lazy(() => import('src/pages/dashboard/productmaster/productlist'));
const ProductMasterCreate = lazy(() => import('src/pages/dashboard/productmaster/productcreate'));
const UserList = lazy(() => import('src/pages/dashboard/newUser/user'));
const RoleList = lazy(() => import('src/pages/dashboard/newUser/role'));
const ScreenList = lazy(() => import('src/pages/dashboard/newUser/screen'));
const SupplierProduct = lazy(() => import('src/pages/dashboard/supplier/product'));
const SupplierEditPage = lazy(() => import('src/pages/dashboard/supplier/supplierEdit'));
const SupplierProductPage = lazy(() => import('src/pages/dashboard/supplier/sup product'));

const DeliveryListPage = lazy(() => import('src/pages/dashboard/delivery/list'));
const OverviewBookingPage1 = lazy(() => import('src/pages/dashboard/stdash/booking'));

const SupplierStorePage = lazy(() => import('src/pages/dashboard/supplier/supplierstore'));
const Overall = lazy(() => import('src/pages/dashboard/newUser/overall'));
// REPORT
const ReportCreatePage = lazy(() => import('src/pages/dashboard/report/new'));

// ----------------------------------------------------------------------
const DetermineIndexPage = () => {
  const userdata = Contextid();
  console.log(userdata); // Assuming Contextid provides the user role
  const userRole = userdata.role_id;
  console.log(userRole);
  const [loading, setLoading] = useState(true);
  const [rolePaths, setRolePaths] = useState('');

  const fetchRolePathsFromDatabase = async (id) => {
    const Data = {
      ID: id,
    };
    try {
      const response = await axios.put(endpoints.nav.path, Data);
      if (response.status === 200) {
        const responseData = response.data;
        console.log(responseData);
        setRolePaths(responseData[0].p_path);
        console.log(responseData[0].p_path);
        setLoading(false);
      } else {
        console.error('Unexpected  Response Status:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    console.log(userRole);
    if (userRole === 0) {
      setRolePaths('/dashboardstore');
      setLoading(false);
    } else {
      // fetchRolePathsFromDatabase(userRole)
      fetchRolePathsFromDatabase(userRole);
    }
  }, [userRole]);

  if (loading) {
    return <LoadingScreen />;
  }
  return rolePaths === '/dashboardstore' ? <IndexPage /> : <Navigate to={rolePaths} />;
};

export const dashboardRoutes2 = [
  {
    path: 'dashboardstore',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      // { element: <IndexPage />, index: true },
      {
        element: (
          // Render the determined index page based on the user's role
          <DetermineIndexPage />
        ),
        index: true,
      },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'profile', element: <AccountPagestore /> },
      {
        path: 'dash',
        children: [
          // { path: 'dash', element: <OverviewBookingPage1 /> },
          { path: 'dashapp', element: <OverviewAppPage /> },
        ],
      },
      {
        path: 'supplier',
        children: [
          { element: <SupplierListView />, index: true },
          { path: 'view', element: <SupplierListView /> },
          { path: 'productsupplier', element: <SupplierProductPage /> },
          // { path: 'view/order', element: <SupplierProductOrder /> },
          { path: ':id/edit', element: <SupplierEditPage /> },
          { path: ':id/product', element: <SupplierProduct /> },
          { path: 'order', element: <OrderListView /> },

          { path: ':supplierid/profile', element: <SupplierProfilePage /> },
          // { path: ':id/productedit', element: <SupplierProductEditPage /> },
        ],
      },
      // {
      //   path: 'productmaster',
      //   children: [
      //     { path: 'productlist', element: <ProductMaster /> },
      //     { path: 'productcreate', element: <ProductMasterCreate /> },
      //   ],
      // },
      {
        path: 'purchase',
        children: [
          { element: <PurchaseCreate />, index: true },
          { path: '', element: <PurchaseCreate /> },
          { path: 'invoice/:id/:receivedOnly', element: <PurchaseInvoiceDetailsPage /> },
          { path: 'list', element: <PurchaseListPage /> },
          { path: 'return/list', element: <ReturnListPage /> },
          { path: 'postedgrn/list', element: <PostedGrnListPage /> },
          { path: ':id/:i_sup/edit', element: <PurchaseEditPage /> },
          { path: ':id/grnlist', element: <GrnListPage /> },
          { path: ':id/:supplierid/grnview', element: <GrnViewPage /> },
          { path: ':id/:supplierid/:pendingView/grnview', element: <GrnViewPage /> },
          { path: ':id/:supplierid/:grnMasterId/returngrnview', element: <RtnGrnViewPage /> },
          { path: ':id/:supplierid/:grnMasterId/:pendingView/returngrnview', element: <RtnGrnViewPage /> },
          { path: ':id/:supplierid/:store_id/qualitycheck', element: <GrnToQualityPage /> },
          { path: 'supplier/invoice/:id/:storeid/:supplier_id/:receivedOnly', element: <SupplierPoInvoiceDetailsPage /> },
          // { path: ':id/product', element: <SupplierProduct /> },
        ],
      },
      {
        path: 'cuser',
        children: [{ path: 'profile', element: <UserProfilePage /> }],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'report',
        children: [{ path: 'reportcreat', element: <ReportCreatePage /> }],
      },
      {
        path: 'customer',
        children: [
          { element: <CusList />, index: true },

          { path: ':id', element: <ProductDetails /> },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: 'master', element: <CusList /> },
          { path: 'subcat', element: <SubcatList /> },
          { path: 'unit', element: <UnitList /> },
          { path: 'typemaster', element: <TypeList /> },
          { path: 'typenew', element: <TypeCreatePage /> },
          { path: 'dismaster', element: <DisList /> },
          { path: 'disnew', element: <DisCreatePage /> },
          { path: 'giftmaster', element: <GiftList /> },
          { path: 'coumaster', element: <CouList /> },
          { path: ':id/profile', element: <UserProfilePage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'delivery',
        children: [
          { element: <DeliveryListPage />, index: true },
          { path: 'list', element: <DeliveryListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'sales',
        children: [
          { element: <SalesInvoiceListPage />, index: true },
          { path: 'master', element: <SalesInvoiceListPage /> },
          { path: ':id', element: <SalesInvoiceDetailsPage /> },
          { path: ':id/edit', element: <SalesInvoiceEditPage /> },
          { path: 'new', element: <SalesInvoiceCreatePage /> },
          { path: ':id/edit1', element: <Salesret /> },
          { path: ':id/:cusid', element: <SalesInvoiceDetailsPage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'inventory',
        children: [
          { element: <ProductList />, index: true },
          { path: 'list', element: <ProductList /> },
          { path: ':id', element: <ProductDetails /> },
          { path: 'new', element: <ProductCreate /> },
          { path: ':id/edit', element: <ProductEdit /> },
          { path: 'listall', element: <ProductListall /> },
        ],
      },
      {
        path: 'productmaster',
        children: [
          { element: <ProductMaster />, index: true },

          { path: 'productlist', element: <ProductMaster /> },
          { path: 'productcreate', element: <ProductMasterCreate /> },
        ],
      },

      {
        path: 'productmaster',
        children: [
          { path: 'category', element: <CategoryList /> },
          { path: 'subcat', element: <SubcatList /> },
          { path: 'unit', element: <UnitList /> },
          { path: 'invoicecredit', element: <InvoiceCreditPage /> },
        ],
      },

      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <UserList />, index: true },
          { path: 'create', element: <UserList /> },
          { path: 'role', element: <RoleList /> },
          {
            path: ':id/list',
            element: <ScreenList />,
          },
          { path: 'overall', element: <Overall /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
