/* eslint-disable no-dupe-keys */
import axios from 'axios';

import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({ baseURL: HOST_API });
console.log('hh', HOST_API);
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    // me: '/api/auth/me',
    me: '/api/auth/user',
    login: '/api/auth/login',
    refresh: '/api/auth/refresh',
    register: '/api/auth/register',
    storelogin: '/api/auth/storelogin',
    forgot: '/api/forgot/forgot',
    reset: '/api/forgot/reset',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  category: {
    categoryinsert: '/api/category/categoryinsert',
    categorydata: (admin_id) => `/api/category/categorydata/${admin_id}`,
    categoryedit: '/api/category/categoryedit',
    categoryupdate: '/api/category/categoryupdate',
    toggleStatus: '/api/category/toggleStatus',
    delete: '/api/category/delete',
    deleteall: '/api/category/deleteall',
    catget: '/api/category/catget', // for bulk upload
  },
  subcategory: {
    insert: '/api/subcategory/insert',
    data: (admin_id) => `/api/subcategory/data/${admin_id}`,
    edit: '/api/subcategory/edit',
    update: '/api/subcategory/update',
    toggleStatus: '/api/subcategory/toggleStatus',
    delete: '/api/subcategory/delete',
    deleteall: '/api/subcategory/deleteall',
    subcatget: '/api/subcategory/subcatget', // for bulk upload
  },
  unit: {
    insert: '/api/unit/insert',
    data: (admin_id) => `/api/unit/data/${admin_id}`,
    edit: '/api/unit/edit',
    update: '/api/unit/update',
    toggleStatus: '/api/unit/toggleStatus',
    delete: '/api/unit/delete',
    deleteall: '/api/unit/deleteall',
    unitget: '/api/unit/unitget', // for bulk upload
  },
  product: {
    insert: '/api/product/insert',
    data: (admin_id, storeid) => `/api/product/data/${admin_id}/${storeid}`,
    edit: '/api/product/edit',
    update: '/api/product/update',
    toggleStatus: '/api/product/toggleStatus',
    delete: '/api/product/delete',
    deleteall: '/api/product/deleteall',
    get: '/api/product/get',
    bulkupload: '/api/product/bulkupload',
    credit: '/api/product/credit',
    creditget: '/api/product/creditget',
    creditupdate: '/api/product/creditupdate',
    getall: '/api/product/getall',
  },
  tax: {
    data: (admin_id) => `/api/tax/data/${admin_id}`,
    data1: (admin_id, taxsystem) => `/api/tax/data1/${admin_id}/${taxsystem}`,
    subtax: (admin_id, taxsystem) => `/api/tax/subtax/${admin_id}/${taxsystem}`,

    insert: '/api/tax/insert',
    edit: '/api/tax/edit',
    update: '/api/tax/update',
    toggleStatus: '/api/tax/toggleStatus',
    delete: '/api/tax/delete',
    deleteall: '/api/tax/deleteall',
    countrydata: '/api/tax/countrydata',
    countryget: '/api/tax/countryget',
    taxget: '/api/tax/taxget',
  },
  inventory: {
    insert: '/api/inventory/insert',
    data: (admin_id, store_id) => `/api/inventory/data/${admin_id}/${store_id}`,
    edit: '/api/inventory/edit',
    update: '/api/inventory/update',
    toggleStatus: '/api/inventory/toggleStatus',
    delete: '/api/inventory/delete',
    deleteall: '/api/inventory/deleteall',
    dataall: (admin_id, store_id) => `/api/inventory/dataall/${admin_id}/${store_id}`,
    expense: '/api/inventory/expense',
  },
  role: {
    data: (admin_id, store_id) => `/api/role/data/${admin_id}/${store_id}`,
    insert: '/api/role/insert',
    edit: '/api/role/edit',
    update: '/api/role/update',
    toggleStatus: '/api/role/toggleStatus',
    delete: '/api/role/delete',
    deleteall: '/api/role/deleteall',
    deliver: '/api/role/deliver',
    deliverAdmin: '/api/role/deliverAdmin',
  },
  storeuser: {
    insert: '/api/storeuser/insert',
    messageinsert: '/api/storeuser/messageinsert',
    selectmessage: '/api/storeuser/selectmessage',
    check: '/api/storeuser/check',

    data: (admin_id, storeid) => `/api/storeuser/data/${admin_id}/${storeid}`,
    edit: '/api/storeuser/edit',
    update: '/api/storeuser/update',
    update1: '/api/storeuser/update1',

    toggleStatus: '/api/storeuser/toggleStatus',
    delete: '/api/storeuser/delete',
    deleteall: '/api/storeuser/deleteall',
    roleanduser: (admin_id, storeid) => `/api/storeuser/roleanduser/${admin_id}/${storeid}`,
  },
  supplier: {
    list: (admin_id) => `/api/supplier/list/${admin_id}`,
    edit: (id) => `/api/supplier/edit/${id}`,
    product: (id, admin_id, storeid) => `/api/supplier/${id}/${admin_id}/${storeid}/product`,
    products: `/api/supplier/products`,
    suplist: '/api/supplier/list',
    create: '/api/supplier/create',
    create2: '/api/supplier/create2',
    getCountry: (admin_id) => `/api/supplier/getCountry/${admin_id}`,
    getState: (id) => `/api/supplier/getState/${id}`,
    getDistrict: (id) => `/api/supplier/getDistrict/${id}`,
    getCity: (id) => `/api/supplier/getCity/${id}`,
    allState: (admin_id) => `/api/supplier/all/getState/${admin_id}`,
    allDistrict: (admin_id) => `/api/supplier/all/getDistrict/${admin_id}`,
    allCity: (admin_id) => `/api/supplier/all/getCity/${admin_id}`,
    delete: '/api/supplier/delete',
    update: (id) => `/api/supplier/update/${id}`,
    getProduct: (admin_id, storeid) => `/api/supplier/getProduct/${admin_id}/${storeid}`,
    submitproduct: '/api/supplier/submitproduct',
    supplierproduct: '/api/supplier/supplierproduct',
    productedit: (id) => `/api/supplier/productedit/${id}`,
    productdelete: '/api/supplier/productdelete',
    bulkupload: '/api/supplier/bulkupload',
    productget: '/api/supplier/productget',
    bulkDelete: '/api/supplier/bulkDelete',
    supplierbulkdelete: '/api/supplier/supplierbulkdelete',
    supplierbulkupload: '/api/supplier/supplierbulkupload',
    profile: (id, admin_id) => `/api/supplier/profile/${id}/${admin_id}`,
    getStoreName: '/api/supplier/getStoreName',
    getStoreList: '/api/supplier/getStoreList',
    submitstore: '/api/supplier/submitstore',
    suplierstore: (admin_id, storeid) => `/api/supplier/supplierstore/${admin_id}/${storeid}`,
    storesup: (admin_id, storeid, supid) => `/api/supplier/storesup/${admin_id}/${storeid}/${supid}`,
    getStoreAssign: (admin_id, supplierid) => `/api/supplier/getStoreAssign/${admin_id}/${supplierid}`,
    removestorefrom: '/api/supplier/removestorefrom',
    supdetailscount: '/api/supplier/supdetailscount',
  },
  customer: {
    list: (admin_id, storeid, random, random1, random2) => `/api/customer/list?${random1}=${random}&admin_id=${admin_id}&storeid=${storeid}`,
    // edit: (id) => `/api/customer/edit/${id}`,
    generalapi: '/api/customer/generalapi',

    insert: '/api/customer/insert',
    edit: '/api/customer/edit',
    delete: '/api/customer/delete',
    bulkDelete: '/api/customer/bulkDelete',
    bulkupload: '/api/customer/bulkupload',
    typelist: (admin_id, storeid) => `/api/customer/typelist/${admin_id}/${storeid}`,
    typelist1: (admin_id, storeid) => `/api/customer/typelist1/${admin_id}/${storeid}`,

    typeinsert: '/api/customer/typeinsert',
    typeget: '/api/customer/typeget',
    typeedit: '/api/customer/typeedit',
    typedelete: '/api/customer/typedelete',
    typebulkDelete: '/api/customer/typebulkDelete',
    typeliststatus: (admin_id, storeid) => `/api/customer/typeliststatus/${admin_id}/${storeid}`,
    dislist: (admin_id, storeid) => `/api/customer/dislist/${admin_id}/${storeid}`,
    disinsert: '/api/customer/disinsert',
    disedit: '/api/customer/disedit',
    disdelete: '/api/customer/disdelete',
    disbulkDelete: '/api/customer/disbulkDelete',
    discount: (admin_id, storeid) => `/api/customer/discount/${admin_id}/${storeid}`,
    giftlist: (admin_id, storeid) => `/api/customer/giftlist/${admin_id}/${storeid}`,
    giftinsert: '/api/customer/giftinsert',
    giftedit: '/api/customer/giftedit',
    giftdelete: '/api/customer/giftdelete',
    giftbulkDelete: '/api/customer/giftbulkDelete',
    insertsales: '/api/customer/insertsales',
    coulist: (admin_id, storeid) => `/api/customer/coulist/${admin_id}/${storeid}`,
    couedit: '/api/customer/couedit',
    couinsert: '/api/customer/couinsert',
    bulkupload: '/api/customer/bulkupload',
    giftlistactive: (admin_id, storeid) => `/api/customer/giftlistactive/${admin_id}/${storeid}`,
    coulistactive: (admin_id, storeid) => `/api/customer/coulistactive/${admin_id}/${storeid}`,
    cusdata: (admin_id, storeid, id) => `/api/customer/cusdata/${admin_id}/${storeid}/${id}`,
    coudelete: '/api/customer/coudelete',
    coubulkDelete: '/api/customer/coubulkDelete',
    getall: '/api/customer/getall',
  },
  sales: {
    listproduct: (admin_id, storeid) => `/api/sales/listproduct/${admin_id}/${storeid}`,
    salesedit: (id) => `/api/sales/salesedit/${id}`,
    invoicecredit: (mstore, admin_id) => `/api/sales/invoicecredit/${mstore}/${admin_id}`,

    listcustomer: (admin_id, storeid) => `/api/sales/listcustomer/${admin_id}/${storeid}`,
    typedis: (admin_id, storeid, type) => `/api/sales/typedis/${admin_id}/${storeid}/${type}`,
    salecoinsert: '/api/sales/salecoinsert',
    salevoinsert: '/api/sales/salevoinsert',
    getclaim: (admin_id, storeid, customer) => `/api/sales/getclaim/${admin_id}/${storeid}/${customer}`,
    coupupdate: '/api/sales/coupupdate',
    coupupdate2: '/api/sales/coupupdate2',

    salesinsert: '/api/sales/salesinsert',
    claimupdate: '/api/sales/claimupdate',
    deletecovo: '/api/sales/deletecovo',

    salesorderlist: (admin_id, storeid) => `/api/sales/salesorderlist/${admin_id}/${storeid}`,
    deliveryin: (admin_id, storeid) => `/api/delivery/deliveryin/${admin_id}/${storeid}`,
    deliverydetails: (id) => `/api/delivery/deliverydetails/${id}`,
    deliverydetails1: '/api/delivery/deliverydetails1',

    listdelivery: (admin_id, storeid) => `/api/delivery/listdelivery/${admin_id}/${storeid}`,
    deliveryuser: (admin_id, storeid, role_id) => `/api/delivery/deliveryuser/${admin_id}/${storeid}/${role_id}`,
    user: (admin_id, storeid, id) => `/api/delivery/user/${admin_id}/${storeid}/${id}`,
    updatestatus: (admin_id, store_id, id) => `/api/delivery/updatestatus/${admin_id}/${store_id}/${id}`,
    updatestatus1: (admin_id, store_id, id) => `/api/delivery/updatestatus1/${admin_id}/${store_id}/${id}`,
    deliverychange: (admin_id, store_id, id) => `/api/sales/deliverychange/${admin_id}/${store_id}/${id}`,
    deliverychange1: (admin_id, store_id, id) => `/api/sales/deliverychange1/${admin_id}/${store_id}/${id}`,
    listcustomername1: '/api/sales/listcustomername1',

    listcustomername: (admin_id, storeid, cus_id) => `/api/sales/listcustomername/${admin_id}/${storeid}/${cus_id}`,
    listproductdetails: (admin_id, storeid) => `/api/sales/listproductdetails/${admin_id}/${storeid}`,
    listproductunit: (admin_id, storeid) => `/api/sales/listproductunit/${admin_id}`,
    product: (admin_id, storeid, id) => `/api/sales/product/${admin_id}/${storeid}/${id}`,
    product1: '/api/sales/product1',
    getall: '/api/sales/getall',
    getall1: '/api/sales/getall1',
    getdetails: '/api/sales/getdetails',
    getmasteranddetails: '/api/sales/getmasteranddetails',

    salesupdate: '/api/sales/salesupdate',
    salescancelup: (id) => `/api/sales/salescancelup/${id}`,
    salesreturninsert: '/api/sales/salesreturninsert',
    salesorderlistindi: (admin_id, storeid, cusid) => `/api/sales/salesorderlistindi/${admin_id}/${storeid}/${cusid}`,
    totalamount: (admin_id, storeid, cusid) => `/api/sales/totalamount/${admin_id}/${storeid}/${cusid}`,
    salesdetails: (admin_id, storeid, cusid) => `/api/sales/salesdetails/${admin_id}/${storeid}/${cusid}`,
    storedetails: (admin_id, store_id) => `/api/sales/storedetails/${admin_id}/${store_id}`,
    insertdelivery: (admin_id, store_id, id, role) => `/api/delivery/insertdelivery/${admin_id}/${store_id}/${id}/${role}`,
    deliverydata: (admin_id, storeid) => `/api/delivery/deliverydata/${admin_id}/${storeid}`,
  },
  purchase: {
    supplier: `/api/purchase/get/supplier`,
    storeAndSup: `/api/purchase/storeandsup`,
    updatePMRstatus: `/api/purchase/update/rtn/pmstatus`,
    updatePMstatus: `/api/purchase/update/pmstatus`,
    dltPartialRetMas: `/api/purchase/dlt/partialreturnmaster`,
    dltReturnMaster: `/api/purchase/dlt/returnmaster`,
    dltSupOrder: `/api/purchase/supplier/dltfullorder`,
    dltSupOrderPartial: `/api/purchase/supplier/dltpartialorder`,
    // supplierHistory: `/api/purchase/supplier/purchasedetails`, // purchase details of supplier
    PurchaseHistory: `/api/purchase/supplier/purchasehistory`, // purchase master of particular supplier
    cancelPo: `/api/purchase/dlt/purchasemaster`,
    cancelDetails: `/api/purchase/dlt/purchasedetails`,
    grnMasterDlt: `/api/purchase/dlt/grnmaster`,
    orderEdit: (id, admin_id, store_id) => `/api/purchase/order/edit/${admin_id}/${store_id}/${id}`,
    returnedDetails: '/api/purchase/return/details',
    updategrnstatus: '/api/purchase/update/grnstatus',
    returncolapse: `/api/purchase/return/list`,
    qualitycheck: `/api/purchase/qualitycheck`,
    grndetails: `/api/purchase/postedgrn/details`,
    postedgrnlist: `/api/purchase/postedgrn/list`,
    postGrnMaster: `/api/purchase/grn/master`,
    postGrn: `/api/purchase/receive/grn`,
    postReturnGrn: `/api/purchase/receive/returngrn`,
    grnentry: `/api/purchase/grn/entry`,
    pendingGrnEntry: `/api/purchase/pending/grn/entry`,
    returnGrnEntry: `/api/purchase/grn/returnEntry`,
    pendingReturnGrnEntry: `/api/purchase/grn/pending/returnEntry`,
    grnlist: (id, admin_id, store_id) => `/api/purchase/${id}/${admin_id}/${store_id}/grnlist`,
    postpo: (id, admin_id, store_id) => `/api/purchase/${id}/${admin_id}/${store_id}/postpo`,
    master: (id, admin_id) => `/api/purchase/${id}/${admin_id}/master`,
    details: (id, admin_id, store_id, supplierId) => `/api/purchase/${id}/${admin_id}/${store_id}/${supplierId}/details`,
    receivedDetails: (id, admin_id, store_id, supplierId) => `/api/purchase/${id}/${admin_id}/${store_id}/${supplierId}/received/details`,
    invoiceDetails: (id, admin_id, store_id) => `/api/purchase/${id}/${admin_id}/${store_id}/invoice/details`,
    list: (admin_id, store_id) => `/api/purchase/list/${admin_id}/${store_id}`,
    reportlist: (admin_id, store_id) => `/api/purchase/reportlist/${admin_id}/${store_id}`,
    returnlist: (admin_id, store_id) => `/api/purchase/returnlist/${admin_id}/${store_id}`,
    addOrder: '/api/purchase/addOrder',
    editOrder: '/api/purchase/editOrder',
    // products: '/api/supplier/get/product',
    edit: (id) => `/api/supplier/edit/${id}`,
    product: (sup_id) => `/api/supplier/product/${sup_id}`,
    products: '/api/purchase/products',
    supProducts: '/api/purchase/supplierproducts',
    qualityreturn: `/api/purchase/quality/return`,
    qualityaccept: `/api/purchase/quality/accept`,
    completeStatus: `/api/purchase/status/complete`,
    incompleteStatus: `/api/purchase/status/incomplete`,
    returnCompleteStatus: `/api/purchase/returnstatus/complete`,
    returnIncompleteStatus: `/api/purchase/returnstatus/incomplete`,
    checkIsComplete: (admin_id, master_id, supplier_id) => `/api/purchase/fetch/${admin_id}/${master_id}/${supplier_id}/complete`,
    checkIsCompleteReturn: (admin_id, master_id, supplier_id) => `/api/purchase/fetch/${admin_id}/${master_id}/${supplier_id}/return/complete`,
  },
  Report: {
    addStore: '/api/store/storeadd',
  },
  store: {
    addStore: '/api/store/storeadd',
    storeEdit: '/api/store/storeedit',
    storeData: (admin_id) => `/api/store/storelist/${admin_id}`,
    stData: (admin_id, store_id) => `/api/store/stData/${admin_id}/${store_id}`,
    storeToggleStatus: '/api/store/storetoggleStatus',
    storeUpdate: '/api/store/storeupdate',
    storeDelete: '/api/store/storedelete',
    getCountry: (admin_id) => `/api/store/countryget/${admin_id}`,
    getState: (admin_id) => `/api/store/stateget/${admin_id}`,
    getDistrict: (admin_id) => `/api/store/districtget/${admin_id}`,
    getCity: (admin_id) => `/api/store/cityget/${admin_id}`,
    checkUsername: 'api/store/checkusername',
    imageUpload: (id) => `api/store/imageUpload/${id}`,
    store: (admin_id) => `/api/store/store/${admin_id}`,
    sales: (admin_id, store_id) => `/api/store/sales/${admin_id}/${store_id}`,
    country: (admin_id, country) => `/api/store/country/${admin_id}/${country}`,
    currency: (admin_id, cont) => `/api/store/currency/${admin_id}/${cont}`,
    storeprofile: '/api/store/storeprofile',
  },
  country: {
    addCountry: '/api/country/countryread',
    countryedit: '/api/country/countryedit',
    countryData: (admin_id) => `/api/country/countrylist/${admin_id}`,
    CoToggleStatus: '/api/country/cotoggleStatus',
    countryUpdate: '/api/country/countryupdate',
    countryDelete: '/api/country/countrydelete',
    countrydeleteall: '/api/country/countrydeleteall',
    checkExistence: '/api/country/checkcountry',
    countryget: '/api/country/countryget',
  },
  state: {
    addState: '/api/state/stateadd',
    stateEdit: '/api/state/stateedit',
    stateData: (admin_id) => `/api/state/statelist/${admin_id}`,
    StateToggleStatus: '/api/state/statetoggleStatus',
    stateUpdate: '/api/state/stateupdate',
    stateDelete: '/api/state/statedelete',
    checkExistence: '/api/state/checkstate',
    stateget: '/api/state/stateget',
    statedeleteall: '/api/state/statedeleteall',
  },
  district: {
    addDistrict: '/api/district/districtadd',
    districtget: '/api/district/districtget',
    districtEdit: '/api/district/districtedit',
    districtData: (admin_id) => `/api/district/districtlist/${admin_id}`,
    districtToggleStatus: '/api/district/districttoggleStatus',
    districtUpdate: '/api/district/districtupdate',
    districtDelete: '/api/district/districtdelete',
    checkExistence: '/api/district/checkdistrict',
    districtdeleteall: '/api/district/districtdeleteall',
  },
  city: {
    addCity: '/api/city/cityadd',
    cityEdit: '/api/city/cityedit',
    cityData: (admin_id) => `/api/city/citylist/${admin_id}`,
    cityToggleStatus: '/api/city/citytoggleStatus',
    cityUpdate: '/api/city/cityupdate',
    cityDelete: '/api/city/citydelete',
    checkExistence: '/api/city/checkcity',
    citydeleteall: '/api/city/citydeleteall',

    cityget: '/api/city/cityget',
  },
  nav: {
    insert: '/api/nav/insert',
    data: (admin_id, store_id, role_id) => `/api/nav/data/${admin_id}/${store_id}/${role_id}`,
    update: '/api/nav/update',
    path: '/api/nav/path',
  },
  dashboard: {
    getproduct: (admin_id, storeid) => `/api/dashboard/data/${admin_id}/${storeid}`,
    getproduct1: (admin_id, storeid) => `/api/dashboard/data1/${admin_id}/${storeid}`,
    getdelivery: (admin_id, storeid) => `/api/dashboard/delivery/${admin_id}/${storeid}`,
    getcat: (admin_id, storeid) => `/api/dashboard/getcat/${admin_id}/${storeid}`,
  },
  allreports: {
    salesmaster: '/api/allreports/salesmaster',
    storemaster: '/api/allreports/storemaster',
    giftsclaim: '/api/allreports/giftsclaim',
  },
};
