import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------
// const accessToken = localStorage.getItem('accessToken');
// const accessTokenstore = localStorage.getItem('accessTokenstore');

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  DASHBOARD2: '/dashboardstore',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  // product master
  productmaster: {
    root: `/productmaster`,
    // checkout: `/productmaster/checkout`,
    details: (id) => `/productmaster/${id}`,
    demo: {
      details: `/productmaster/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      storelogin: `${ROOTS.AUTH}/jwt/storelogin`,
      forgot: `${ROOTS.AUTH}/jwt/forgot`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    // jjjjj

    supplier: {
      root: `${ROOTS.DASHBOARD}/supplier`,
      new: `${ROOTS.DASHBOARD}/supplier/new`,
      productsupplier: `${ROOTS.DASHBOARD}/supplier/productsupplier`,
      edit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,
      // productedit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,

      // demo: {
      //   edit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,
      // },
      view: `${ROOTS.DASHBOARD}/supplier/view`,
      order: `${ROOTS.DASHBOARD}/supplier/view/order`,
      product: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/product`,
      profile: (supplierid) => `${ROOTS.DASHBOARD}/supplier/${supplierid}/profile`,
      storeadd: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/storeadd`,
    },

    purchase: {
      list: `${ROOTS.DASHBOARD}/purchase`,
      returnlist: `${ROOTS.DASHBOARD}/purchase/return/list`,
      postedgrn: `${ROOTS.DASHBOARD}/purchase/postedgrn/list`,
      details: (id, store_id, supplier_id) => `${ROOTS.DASHBOARD}/purchase/invoice/${id}/${store_id}/${supplier_id}`,
      supplierdetails: (id, store_id, supplier_id) => `${ROOTS.DASHBOARD}/purchase/supplier/invoice/${id}/${store_id}/${supplier_id}`,
      adminDetails: (id, store_id, receivedOnly) => `${ROOTS.DASHBOARD}/purchase/receivedInvoice/${id}/${store_id}/${receivedOnly}`,
    },

    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      create: `${ROOTS.DASHBOARD}/users/create`,
      role: `${ROOTS.DASHBOARD}/users/role`,
      list: (id) => `${ROOTS.DASHBOARD}/users/${id}/list`,
      overall: `${ROOTS.DASHBOARD}/users/overall`,
    },
    generalm: {
      root: `${ROOTS.DASHBOARD}/generalm`,
      country: `${ROOTS.DASHBOARD}/generalm/country`,
      countrycre: `${ROOTS.DASHBOARD}/generalm/countrycre`,
      edit: (id) => `${ROOTS.DASHBOARD}/generalm/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/generalm/${MOCK_ID}/edit`,
      },
      state: `${ROOTS.DASHBOARD}/generalm/state`,
      statecre: `${ROOTS.DASHBOARD}/generalm/statecre`,
      sedit: (id) => `${ROOTS.DASHBOARD}/generalm/${id}/sedit`,
      sdemo: {
        edit: `${ROOTS.DASHBOARD}/generalm/${MOCK_ID}/sedit`,
      },
      district: `${ROOTS.DASHBOARD}/generalm/district`,
      districtcre: `${ROOTS.DASHBOARD}/generalm/districtcre`,
      dedit: (id) => `${ROOTS.DASHBOARD}/generalm/${id}/dedit`,
      ddemo: {
        edit: `${ROOTS.DASHBOARD}/generalm/${MOCK_ID}/dedit`,
      },
      city: `${ROOTS.DASHBOARD}/generalm/city`,
      citycre: `${ROOTS.DASHBOARD}/generalm/citycre`,
      cedit: (id) => `${ROOTS.DASHBOARD}/generalm/${id}/cedit`,
      cdemo: {
        edit: `${ROOTS.DASHBOARD}/generalm/${MOCK_ID}/cedit`,
      },
    },
    report: {
      root: `${ROOTS.DASHBOARD}/report`,
      reportcreat: `${ROOTS.DASHBOARD}/report/reportcreat`,
    },
    store: {
      root: `${ROOTS.DASHBOARD}/store`,
      StoreMaster: `${ROOTS.DASHBOARD}/store/list`,
      Storecreat: `${ROOTS.DASHBOARD}/store/Storecreat`,
      edit: (id) => `${ROOTS.DASHBOARD}/store/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/store/${MOCK_ID}/edit`,
      },
      profile: (id) => `${ROOTS.DASHBOARD}/store/${id}/profile`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    // product master oct 16
    productmaster: {
      root: `${ROOTS.DASHBOARD}/productmaster`,
      // new: `${ROOTS.DASHBOARD}/productmaster/new`,
      // details: (id) => `${ROOTS.DASHBOARD}/productmaster/${id}`,
      // edit: (id) => `${ROOTS.DASHBOARD}/productmaster/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/productmaster/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/productmaster/${MOCK_ID}/edit`,
      // },
      category: `${ROOTS.DASHBOARD}/productmaster/category`, // oct 17
      // catadd: `${ROOTS.DASHBOARD}/productmaster/catadd`, // oct 17 deleted-> oct 19
      subcat: `${ROOTS.DASHBOARD}/productmaster/subcat`, // oct 17
      // subcatadd: `${ROOTS.DASHBOARD}/productmaster/subcatadd`, // oct 17 deleted-> oct 19
      unit: `${ROOTS.DASHBOARD}/productmaster/unit`, // oct 18
      // unitadd: `${ROOTS.DASHBOARD}/productmaster/unitadd`, // oct 18 deleted-> oct 19
      tax: `${ROOTS.DASHBOARD}/productmaster/tax`, // nov 2
      productlist: `${ROOTS.DASHBOARD}/productmaster/productlist`, // nov 6
      productcreate: `${ROOTS.DASHBOARD}/productmaster/productcreate`, // nov 2
    },
    inventory: {
      listall: `${ROOTS.DASHBOARD}/inventory/listall`,

      root: `${ROOTS.DASHBOARD}/inventory`,
      new: `${ROOTS.DASHBOARD}/inventory/new`,
      details: (id) => `${ROOTS.DASHBOARD}/inventory/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/inventory/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/inventory/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/inventory/${MOCK_ID}/edit`,
      },
    },

    // invoice: {
    //   root: `${ROOTS.DASHBOARD}/invoice`,
    //   new: `${ROOTS.DASHBOARD}/invoice/new`,
    //   details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
    //     edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
    //   },
    // },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
    sales: {
      root: `${ROOTS.DASHBOARD}/sales`,
      new: `${ROOTS.DASHBOARD}/sales/new`,
      details: (id) => `${ROOTS.DASHBOARD}/sales/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/sales/${id}/edit`,
      edit1: (id) => `${ROOTS.DASHBOARD}/sales/${id}/edit1`,
      details1: (id, cusid) => `${ROOTS.DASHBOARD}/sales/${id}/${cusid}`,

      demo: {
        details: `${ROOTS.DASHBOARD}/sales/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/sales/${MOCK_ID}/edit`,
      },
      master: `${ROOTS.DASHBOARD}/sales/master`,
    },
    customers: {
      root: `${ROOTS.DASHBOARD}/customer`,
      details: (id) => `${ROOTS.DASHBOARD}/customer/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/customer/${id}/edit`,
      master: `${ROOTS.DASHBOARD}/customer/master`,
      new: `${ROOTS.DASHBOARD}/customer/new`,
      profile: (id) => `${ROOTS.DASHBOARD}/customer/${id}/profile`,

      // Type master
      typemaster: `${ROOTS.DASHBOARD}/customer/typemaster`,
      typenew: `${ROOTS.DASHBOARD}/customer/typenew`,
      giftmaster: `${ROOTS.DASHBOARD}/customer/giftmaster`,
      giftnew: `${ROOTS.DASHBOARD}/customer/giftnew`,
      dismaster: `${ROOTS.DASHBOARD}/customer/dismaster`,
      disnew: `${ROOTS.DASHBOARD}/customer/disnew`,
      coumaster: `${ROOTS.DASHBOARD}/customer/coumaster`,
      counew: `${ROOTS.DASHBOARD}/customer/counew`,
    },
    cuser: {
      root: `${ROOTS.DASHBOARD}/cuser`,
      profile: `${ROOTS.DASHBOARD}/cuser/profile`,
    },
    delivery: {
      root: `${ROOTS.DASHBOARD}/delivery`,
      details: (id) => `${ROOTS.DASHBOARD}/delivery/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
  },
  dashboard2: {
    root: ROOTS.DASHBOARD2,
    mail: `${ROOTS.DASHBOARD2}/mail`,
    chat: `${ROOTS.DASHBOARD2}/chat`,
    blank: `${ROOTS.DASHBOARD2}/blank`,
    kanban: `${ROOTS.DASHBOARD2}/kanban`,
    calendar: `${ROOTS.DASHBOARD2}/calendar`,
    fileManager: `${ROOTS.DASHBOARD2}/file-manager`,
    permission: `${ROOTS.DASHBOARD2}/permission`,
    profile: `${ROOTS.DASHBOARD2}/profile`,

    general: {
      app: `${ROOTS.DASHBOARD2}/app`,
      ecommerce: `${ROOTS.DASHBOARD2}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD2}/analytics`,
      banking: `${ROOTS.DASHBOARD2}/banking`,
      booking: `${ROOTS.DASHBOARD2}/booking`,
      file: `${ROOTS.DASHBOARD2}/file`,
    },
    dash: {
      root: `${ROOTS.DASHBOARD2}`,
      dash: `${ROOTS.DASHBOARD2}/dash/dash`,
      dashapp: `${ROOTS.DASHBOARD2}/dash/dashapp`,

      // productedit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,

      // demo: {
      //   edit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,
      // },
      view: `${ROOTS.DASHBOARD2}/supplier/view`,
      order: `${ROOTS.DASHBOARD2}/supplier/view/order`,
      product: (id) => `${ROOTS.DASHBOARD2}/supplier/${id}/product`,
    },
    supplier: {
      root: `${ROOTS.DASHBOARD2}/supplier`,
      new: `${ROOTS.DASHBOARD2}/supplier/new`,
      productsupplier: `${ROOTS.DASHBOARD2}/supplier/productsupplier`,
      edit: (id) => `${ROOTS.DASHBOARD2}/supplier/${id}/edit`,
      // productedit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,

      // demo: {
      //   edit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,
      // },
      view: `${ROOTS.DASHBOARD2}/supplier/view`,
      order: `${ROOTS.DASHBOARD2}/supplier/view/order`,
      product: (id) => `${ROOTS.DASHBOARD2}/supplier/${id}/product`,
      profile: (supplierid) => `${ROOTS.DASHBOARD2}/supplier/${supplierid}/profile`,
      storeadd: (id) => `${ROOTS.DASHBOARD2}/supplier/${id}/storeadd`,
    },
    // productmaster: {
    //   root: `${ROOTS.DASHBOARD2}/productmaster`,
    //   productlist: `${ROOTS.DASHBOARD2}/productmaster/productlist`, // nov 6
    //   productcreate: `${ROOTS.DASHBOARD2}/productmaster/productcreate`, // nov 2
    // },
    productmaster: {
      root: `${ROOTS.DASHBOARD2}/productmaster`,
      category: `${ROOTS.DASHBOARD2}/productmaster/category`, // oct 17
      subcat: `${ROOTS.DASHBOARD2}/productmaster/subcat`, // oct 17
      unit: `${ROOTS.DASHBOARD2}/productmaster/unit`, // oct 18
      tax: `${ROOTS.DASHBOARD2}/productmaster/tax`, // nov 2
      productlist: `${ROOTS.DASHBOARD2}/productmaster/productlist`, // nov 6
      productcreate: `${ROOTS.DASHBOARD2}/productmaster/productcreate`, // nov 2
      invoicecredit: `${ROOTS.DASHBOARD2}/productmaster/invoicecredit`,
    },
    inventory: {
      listall: `${ROOTS.DASHBOARD2}/inventory/listall`,

      root: `${ROOTS.DASHBOARD2}/inventory`,
      new: `${ROOTS.DASHBOARD2}/inventory/new`,
      details: (id) => `${ROOTS.DASHBOARD2}/inventory/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD2}/inventory/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD2}/inventory/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD2}/inventory/${MOCK_ID}/edit`,
      },
    },
    // supplier: {
    //   root: `${ROOTS.DASHBOARD2}/supplier`,
    //   new: `${ROOTS.DASHBOARD2}/supplier/new`,
    //   edit: (id) => `${ROOTS.DASHBOARD2}/supplier/${id}/edit`,
    //   // demo: {
    //   //   edit: (id) => `${ROOTS.DASHBOARD}/supplier/${id}/edit`,
    //   // },
    //   view: `${ROOTS.DASHBOARD2}/supplier/view`,
    //   order: `${ROOTS.DASHBOARD2}/supplier/view/order`,
    // },
    purchase: {
      qualitycheck: (id, supplier_id, store_id) => `${ROOTS.DASHBOARD2}/purchase/${id}/${supplier_id}/${store_id}/qualitycheck`,
      grnview: (id, supplierid, pendingView) => `${ROOTS.DASHBOARD2}/purchase/${id}/${supplierid}/${pendingView}/grnview`,
      grnlist: (id) => `${ROOTS.DASHBOARD2}/purchase/${id}/grnlist`,
      edit: (id, i_sup) => `${ROOTS.DASHBOARD2}/purchase/${id}/${i_sup}/edit`,
      root: `${ROOTS.DASHBOARD2}/purchase`,
      list: `${ROOTS.DASHBOARD2}/purchase/list`,
      postedgrn: `${ROOTS.DASHBOARD2}/purchase/postedgrn/list`,
      returnlist: `${ROOTS.DASHBOARD2}/purchase/return/list`,
      returngrnview: (id, supplierid, grnMasterId, pendingView) =>
        `${ROOTS.DASHBOARD2}/purchase/${id}/${supplierid}/${grnMasterId}/${pendingView}/returngrnview`,
      details: (id, receivedOnly) => `${ROOTS.DASHBOARD2}/purchase/invoice/${id}/${receivedOnly}`,
      supplierdetails: (id, store_id, supplier_id, receivedOnly) =>
        `${ROOTS.DASHBOARD2}/purchase/supplier/invoice/${id}/${store_id}/${supplier_id}/${receivedOnly}`,
    },

    users: {
      root: `${ROOTS.DASHBOARD2}/users`,
      create: `${ROOTS.DASHBOARD2}/users/create`,
      role: `${ROOTS.DASHBOARD2}/users/role`,
      list: (id) => `${ROOTS.DASHBOARD2}/users/${id}/list`,
      overall: `${ROOTS.DASHBOARD2}/users/overall`,
    },
    product: {
      root: `${ROOTS.DASHBOARD2}/product`,
      new: `${ROOTS.DASHBOARD2}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD2}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD2}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD2}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD2}/product/${MOCK_ID}/edit`,
      },
    },

    customers: {
      root: `${ROOTS.DASHBOARD2}/customer`,
      details: (id) => `${ROOTS.DASHBOARD2}/customer/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD2}/customer/${id}/edit`,
      master: `${ROOTS.DASHBOARD2}/customer/master`,
      new: `${ROOTS.DASHBOARD2}/customer/new`,
      profile: (id) => `${ROOTS.DASHBOARD2}/customer/${id}/profile`,

      // Type master
      typemaster: `${ROOTS.DASHBOARD2}/customer/typemaster`,
      typenew: `${ROOTS.DASHBOARD2}/customer/typenew`,
      giftmaster: `${ROOTS.DASHBOARD2}/customer/giftmaster`,
      giftnew: `${ROOTS.DASHBOARD2}/customer/giftnew`,
      dismaster: `${ROOTS.DASHBOARD2}/customer/dismaster`,
      disnew: `${ROOTS.DASHBOARD2}/customer/disnew`,
      coumaster: `${ROOTS.DASHBOARD2}/customer/coumaster`,
      counew: `${ROOTS.DASHBOARD2}/customer/counew`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD2}/invoice`,
      new: `${ROOTS.DASHBOARD2}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD2}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD2}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD2}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD2}/invoice/${MOCK_ID}/edit`,
      },
    },
    sales: {
      root: `${ROOTS.DASHBOARD2}/sales`,
      new: `${ROOTS.DASHBOARD2}/sales/new`,
      details: (id) => `${ROOTS.DASHBOARD2}/sales/${id}`,
      details1: (id, cusid) => `${ROOTS.DASHBOARD2}/sales/${id}/${cusid}`,

      edit: (id) => `${ROOTS.DASHBOARD2}/sales/${id}/edit`,
      edit1: (id) => `${ROOTS.DASHBOARD2}/sales/${id}/edit1`,

      demo: {
        details: `${ROOTS.DASHBOARD2}/sales/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD2}/sales/${MOCK_ID}/edit`,
      },
      master: `${ROOTS.DASHBOARD2}/sales/master`,
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
    cuser: {
      root: `${ROOTS.DASHBOARD2}/cuser`,
      profile: `${ROOTS.DASHBOARD2}/cuser/profile`,
    },
    delivery: {
      root: `${ROOTS.DASHBOARD2}/delivery`,
      details: (id) => `${ROOTS.DASHBOARD2}/delivery/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    report: {
      root: `${ROOTS.DASHBOARD2}/report`,
      reportcreat: `${ROOTS.DASHBOARD2}/report/reportcreat`,
    },
  },
};
