import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { baseApi } from '../Main/ApiMain';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});

export default store;
