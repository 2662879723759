import { Contextid } from 'src/sections/customers/type/customers copy/contex';

export function useMockedUser() {
  const userdata = Contextid();
  let user;

  if (userdata) {
    if (userdata.store_id) {
      if (userdata.loginData && userdata.roleData) {
        user = {
          displayName: userdata.loginData.user_name,
          email: userdata.loginData.user_name,
          password: userdata.loginData.password,
          role: userdata.roleData.role_name,
        };
      } else {
        user = {
          displayName: userdata.storename,
          email: userdata.email,

          // Include other properties as needed
          // password: userdata.password,
          // role: userdata.roleData.role_name,
        };
      }
    } else {
      user = {
        displayName: userdata.username,
        email: userdata.email,
        // password: userdata.password,
        // role: userdata.roleData.role_name,
      };
    }
  } else {
    user = null;
  }
  return { user };
}
