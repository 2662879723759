import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import axios, { endpoints } from 'src/utils/axios';

import { AuthContext } from './auth-context';
import { setSession, isValidToken, setSessionstore } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'STORELOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const STORAGE_KEY = 'accessToken';
const STORAGE_KEYSTORE = 'accessTokenstore';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();
  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const accessTokenstore = sessionStorage.getItem(STORAGE_KEYSTORE);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const tokenUser = jwtDecode(accessToken);
        const user = tokenUser;

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
      } else if (accessTokenstore && isValidToken(accessTokenstore)) {
        const tokenUser = jwtDecode(accessTokenstore);
        const users = tokenUser;

        await setSessionstore(accessTokenstore, users.lastid); // Wait for setSessionstore to complete

        const response = await axios.post(endpoints.auth.me, { user: users });

        const user = response.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessTokenstore,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = response.data;
    // console.log(user);
    setSession(accessToken);
    // console.log(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // STORE LOGIN
  const loginstore = useCallback(async (email, password) => {
    const data = { email, password };
    const response = await axios.post(endpoints.auth.storelogin, data);
    const { accessToken, user } = response.data;
    // console.log('response', response.data);

    const userid = user.lastid;
    // console.log(userid);

    setSessionstore(accessToken, userid);
    // console.log(user);
    dispatch({
      type: 'STORELOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });

    // Now, the token should be set before calling initialize
    // await initialize();
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    setSessionstore(null, null);
    // router.push(paths.auth.jwt.login);
    // sessionStorage.removeItem('accessTokenstore');
    // sessionStorage.removeItem('accessToken');
    window.location.href = paths.auth.jwt.register;
    setTimeout(() => {
      dispatch({
        type: 'LOGOUT',
      });
    }, [1000]);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  // console.log(checkAuthenticated);
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      loginstore,
    }),
    [login, logout, register, loginstore, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
