import { Navigate, useRoutes } from 'react-router-dom';

import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN2, PATH_AFTER_LOGIN3, PATH_AFTER_LOGIN4 } from 'src/config-global';

import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { dashboardRoutes2 } from './dashboard2';

export default function Router() {
  const isJwtRegisterView = false;

  const accessToken = sessionStorage.getItem('accessToken');
  const accessTokenstore = sessionStorage.getItem('accessTokenstore');
  // console.log('accessToken:', accessToken);
  // console.log('accessTokenstore:', accessTokenstore);
  let nav = 1;
  const routes = [
    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,
    // Main routes
    ...mainRoutes,
    // Components routes
    ...componentsRoutes,
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  if (accessToken !== null && accessTokenstore === null) {
    // Include dashboardRoutes if accessToken is present
    routes.push(...dashboardRoutes);
    // console.log('accessToken:');
  } else if (accessTokenstore != null && accessToken === null) {
    // console.log('accessToken:');
    nav = 2;

    // Include dashboardRoutes2 if accessTokenstore is present
    routes.push(...dashboardRoutes2);
  }
  // console.log('accessToken:', accessToken);
  // console.log('accessTokenstore:', accessTokenstore);

  // console.log('Routes:', routes);
  const targetPath = accessToken ? PATH_AFTER_LOGIN : PATH_AFTER_LOGIN4;
  const targetPath2 = accessTokenstore ? PATH_AFTER_LOGIN2 : PATH_AFTER_LOGIN3;
  // console.log(targetPath2);
  // console.log(targetPath);

  return useRoutes([
    // Set the index page route based on the access tokens
    {
      path: '/',
      element: <Navigate to={nav === 2 ? targetPath2 : targetPath} replace />,
    },
    // Include the rest of the routes
    ...routes,
  ]);
}
