import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { OrderListView } from 'src/sections/order/view';

// ----------------------------------------------------------------------

// OVERVIEW
import OverviewAppPageadmin from 'src/pages/dashboard/dash/app';

const IndexPage = lazy(() => import('src/pages/dashboard/dash/appadmin'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// PRODUCT master oct 16
const ProductDetails = lazy(() => import('src/pages/dashboard/productmaster/details'));
const ProductEdit = lazy(() => import('src/pages/dashboard/productmaster/edit'));
const CategoryList = lazy(() => import('src/pages/dashboard/productmaster/category')); // oct 17
// const CategoryList = lazy(() => import('src/pages/dashboard/productmaster/catadd')); // oct 17 deleted-> oct 19
const SubcatList = lazy(() => import('src/pages/dashboard/productmaster/subcat')); // oct 17
// const SubcatListAdd = lazy(() => import('src/pages/dashboard/productmaster/subcatadd')); // oct 17deleted-> oct 19
const UnitList = lazy(() => import('src/pages/dashboard/productmaster/unit')); // oct 18
// const UnitListAdd = lazy(() => import('src/pages/dashboard/productmaster/unitadd')); // oct 18deleted-> oct 19
const TaxList = lazy(() => import('src/pages/dashboard/productmaster/tax')); // nov 2
const ProductMaster = lazy(() => import('src/pages/dashboard/productmaster/productlist'));
const ProductMasterCreate = lazy(() => import('src/pages/dashboard/productmaster/productcreate'));

// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));

// REPORT
// REPORT
const ReportCreatePage = lazy(() => import('src/pages/dashboard/report/new'));

// USER
// const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// GENERAL
const CountryListPage = lazy(() => import('src/pages/dashboard/general/countrylist'));
const CountryCreatPage = lazy(() => import('src/pages/dashboard/general/countrycreat'));
const CountryEditPage = lazy(() => import('src/pages/dashboard/general/countryedit'));
const StateListPage = lazy(() => import('src/pages/dashboard/general/statelist'));
const StateCreatPage = lazy(() => import('src/pages/dashboard/general/statecreat'));
const StateEditPage = lazy(() => import('src/pages/dashboard/general/stateedit'));
const DistrictListPage = lazy(() => import('src/pages/dashboard/general/districtlist'));
const DistrictCreatePage = lazy(() => import('src/pages/dashboard/general/districtcreat'));
const DistrictEditPage = lazy(() => import('src/pages/dashboard/general/districtedit'));
const CityListPage = lazy(() => import('src/pages/dashboard/general/citylist'));
const CityCreatePage = lazy(() => import('src/pages/dashboard/general/citycreat'));
const CityEditPage = lazy(() => import('src/pages/dashboard/general/cityedit'));

// const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
// const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
// const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
// const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// STORE

const StoreProfilePage = lazy(() => import('src/pages/dashboard/store/storeprofile'));
// const StoreCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const StoreListPage = lazy(() => import('src/pages/dashboard/store/storelist'));
// const StoreAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const StoreCreatePage = lazy(() => import('src/pages/dashboard/store/storenew'));
// const StoreEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// supplier   jjjj
const SupplierCreatePage = lazy(() => import('src/pages/dashboard/supplier/new'));
const SupplierListView = lazy(() => import('src/pages/dashboard/supplier/view'));
// inventory
const ProductList = lazy(() => import('src/pages/dashboard/inventory/list'));
const ProductCreate = lazy(() => import('src/pages/dashboard/inventory/new'));
//  NEW USER nov22
const UserList = lazy(() => import('src/pages/dashboard/newUser/user'));
const RoleList = lazy(() => import('src/pages/dashboard/newUser/role'));

const SupplierProduct = lazy(() => import('src/pages/dashboard/supplier/product'));
const SupplierEditPage = lazy(() => import('src/pages/dashboard/supplier/supplierEdit'));
const SupplierProductPage = lazy(() => import('src/pages/dashboard/supplier/sup product'));
// const SupplierProductEditPage = lazy(() => import('src/pages/dashboard/supplier/supplierproductedit'));
const SupplierProfilePage = lazy(() => import('src/pages/dashboard/supplier/supplierprofile'));
const SupplierStorePage = lazy(() => import('src/pages/dashboard/supplier/supplierstore'));

const ProductListall = lazy(() => import('src/pages/dashboard/inventory/listall'));

// purchase
// const PurchaseCreate = lazy(() => import('src/pages/dashboard/purchase/new'));
const PurchaseListPage = lazy(() => import('src/pages/dashboard/purchase/list'));
const PurchaseInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/purchase/details'));
const ReturnListPage = lazy(() => import('src/pages/dashboard/purchase/returnlist'));
const PostedGrnListPage = lazy(() => import('src/pages/dashboard/purchase/postedgrn'));

const SupplierPoInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/purchase/supplierPoDetails'));

// --------------------------------------------------------

const CusList = lazy(() => import('src/pages/dashboard/customers/master')); // oct 17
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customers/new'));

// ----------------------------------------------------------------------

const SalesInvoiceListPage = lazy(() => import('src/pages/dashboard/sales/list'));
const SalesInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/sales/details'));
const SalesInvoiceCreatePage = lazy(() => import('src/pages/dashboard/sales/new'));
const SalesInvoiceEditPage = lazy(() => import('src/pages/dashboard/sales/edit'));
const Salesret = lazy(() => import('src/pages/dashboard/sales/return'));

const CustomerEditPage = lazy(() => import('src/pages/dashboard/customers/edit'));
// const TypeEditPage = lazy(() => import('src/pages/dashboard/customers/type/edit'));
const TypeList = lazy(() => import('src/pages/dashboard/customers/type/master'));
const TypeCreatePage = lazy(() => import('src/pages/dashboard/customers/type/new'));

const DisList = lazy(() => import('src/pages/dashboard/customers/discount/master'));
const DisCreatePage = lazy(() => import('src/pages/dashboard/customers/discount/new'));
const GiftList = lazy(() => import('src/pages/dashboard/customers/gift/master'));
const CouList = lazy(() => import('src/pages/dashboard/sales/gift/master'));
const UserProfilePage = lazy(() => import('src/pages/dashboard/cprofile/profile'));
const DeliveryListPage = lazy(() => import('src/pages/dashboard/delivery/list'));
const Overall = lazy(() => import('src/pages/dashboard/newUser/overall'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'supplier',
        children: [
          { element: <SupplierCreatePage />, index: true },
          { path: 'view', element: <SupplierListView /> },
          { path: 'productsupplier', element: <SupplierProductPage /> },
          // { path: 'view/order', element: <SupplierProductOrder /> },
          { path: ':id/edit', element: <SupplierEditPage /> },
          { path: ':id/product', element: <SupplierProduct /> },
          { path: 'order', element: <OrderListView /> },
          // { path: ':id/productedit', element: <SupplierProductEditPage /> },
          { path: ':supplierid/profile', element: <SupplierProfilePage /> },
          { path: ':id/storeadd', element: <SupplierStorePage /> },
        ],
      },
      {
        path: 'dash',
        children: [
          // { path: 'dash', element: <OverviewBookingPage1 /> },
          { path: 'dashapp', element: <OverviewAppPageadmin /> },
        ],
      },
      {
        path: 'purchase',
        children: [
          { element: <PurchaseListPage />, index: true },
          { path: '', element: <PurchaseListPage /> },
          { path: 'return/list', element: <ReturnListPage /> },
          { path: 'postedgrn/list', element: <PostedGrnListPage /> },
          { path: 'invoice/:id/:storeid/:supplier_id', element: <PurchaseInvoiceDetailsPage /> },
          { path: 'supplier/invoice/:id/:storeid/:supplier_id', element: <SupplierPoInvoiceDetailsPage /> },
          { path: 'receivedInvoice/:id/:storeid/:receivedOnly', element: <PurchaseInvoiceDetailsPage /> },
          // { path: 'view/order', element: <SupplierProductOrder /> },
          // { path: ':id/edit', element: <SupplierEditPage /> },
          // { path: ':id/product', element: <SupplierProduct /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'delivery',
        children: [
          { element: <DeliveryListPage />, index: true },
          { path: 'list', element: <DeliveryListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'sales',
        children: [
          { element: <SalesInvoiceListPage />, index: true },
          { path: 'master', element: <SalesInvoiceListPage /> },
          { path: ':id', element: <SalesInvoiceDetailsPage /> },
          { path: ':id/edit', element: <SalesInvoiceEditPage /> },
          { path: 'new', element: <SalesInvoiceCreatePage /> },
          { path: ':id/edit1', element: <Salesret /> },
          { path: ':id/:cusid', element: <SalesInvoiceDetailsPage /> },
        ],
      },
      {
        path: 'report',
        children: [{ path: 'reportcreat', element: <ReportCreatePage /> }],
      },

      {
        path: 'generalm',
        children: [
          // { element: <UserProfilePage />, index: true },
          { path: 'country', element: <CountryListPage /> },
          { path: 'countrycre', element: <CountryCreatPage /> },
          { path: ':id/edit', element: <CountryEditPage /> },
          { path: 'state', element: <StateListPage /> },
          { path: 'statecre', element: <StateCreatPage /> },
          { path: ':id/sedit', element: <StateEditPage /> },
          { path: 'district', element: <DistrictListPage /> },
          { path: 'districtcre', element: <DistrictCreatePage /> },
          { path: ':id/dedit', element: <DistrictEditPage /> },
          { path: 'city', element: <CityListPage /> },
          { path: 'citycre', element: <CityCreatePage /> },
          { path: ':id/cedit', element: <CityEditPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          // { path: 'account', element: <UserAccountPage /> },
        ],
      },

      {
        path: 'store',
        children: [
          { element: <UserProfilePage />, index: true },
          // { path: 'profile', element: <StoreProfilePage /> },
          // { path: 'cards', element: <StoreCardsPage /> },
          { path: 'list', element: <StoreListPage /> },
          { path: 'Storecreat', element: <StoreCreatePage /> },
          { path: ':id/edit', element: <StoreCreatePage /> },
          // { path: 'account', element: <StoreAccountPage /> },
          { path: ':id/profile', element: <StoreProfilePage /> },
        ],
      },

      // PRODUCT master oct 16
      {
        path: 'productmaster',
        children: [
          { path: 'category', element: <CategoryList /> }, // oct 17
          // { path: 'catadd', element: <CategoryList /> }, // oct 17 deleted-> oct 19
          { path: 'subcat', element: <SubcatList /> }, // oct 17
          // { path: 'subcatadd', element: <SubcatListAdd /> }, // oct 17 deleted-> oct 19
          { path: 'unit', element: <UnitList /> }, // oct 18
          // { path: 'unitadd', element: <UnitListAdd /> }, // oct 18 deleted-> oct 19
          { path: 'tax', element: <TaxList /> }, // nov 2
          { path: 'productlist', element: <ProductMaster /> },
          { path: 'productcreate', element: <ProductMasterCreate /> },
        ],
      },
      {
        path: 'inventory',
        children: [
          { element: <ProductList />, index: true },
          { path: 'list', element: <ProductList /> },
          { path: ':id', element: <ProductDetails /> },
          { path: 'new', element: <ProductCreate /> },
          { path: ':id/edit', element: <ProductEdit /> },
          { path: 'listall', element: <ProductListall /> },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <UserList />, index: true },
          { path: 'create', element: <UserList /> },
          { path: 'role', element: <RoleList /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      {
        path: 'customer',
        children: [
          { path: ':id', element: <ProductDetails /> },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: 'master', element: <CusList /> },
          { path: 'subcat', element: <SubcatList /> },
          { path: 'unit', element: <UnitList /> },
          { path: 'typemaster', element: <TypeList /> },
          { path: 'typenew', element: <TypeCreatePage /> },
          { path: 'dismaster', element: <DisList /> },
          { path: 'disnew', element: <DisCreatePage /> },
          { path: 'giftmaster', element: <GiftList /> },
          { path: 'coumaster', element: <CouList /> },
          { path: ':id/profile', element: <UserProfilePage /> },
        ],
      },
      {
        path: 'users',
        children: [
          // { element: <UserList />, index: true },
          // { path: 'create', element: <UserList /> },
          // { path: 'role', element: <RoleList /> },
          // {
          //   path: ':id/list',
          //   element: <ScreenList />,
          // },
          { path: 'overall', element: <Overall /> },
        ],
      },
      {
        path: 'cuser',
        children: [{ path: 'profile', element: <UserProfilePage /> }],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
