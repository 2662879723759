// routes
import { paths } from 'src/routes/paths';
// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.REACT_APP_HOST_API;
// export const HOST_API = 'http://localhost:3002';
export const HOST_API = 'http://103.174.10.74:30000';
// export const HOST_API =apiKey;

// export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const ASSETS_API ='http://103.174.10.74:30000';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const PATH_AFTER_LOGIN2 = paths.dashboard2.root; // as '/dashboard'
export const PATH_AFTER_LOGIN3 = paths.auth.jwt.login; // as '/dashboard'
export const PATH_AFTER_LOGIN4 = paths.auth.jwt.register; // as '/dashboard'
