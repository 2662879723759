import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import SvgColor from 'src/components/svg-color';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

export const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  Supplier: icon('ic_user'),
  Users: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  Products: icon('ic_ecommerce'),
  Inventory: icon('ic_analytics'),
  Dashboard: icon('ic_dashboard'),
  Customers: icon('ic_cust'),
  Sales: icon('ic_bill'),
  Delivery: icon('ic_delivery'),
  users: icon('ic_users'),
  Purchase: icon('ic_purchase'),
  General: icon('ic_setting'),
  Report: icon('ic_analytics'),
};

// ----------------------------------------------------------------------

export function Data() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      // {
      //   subheader: t('Store Dashboard'),
      //   items: [
      //     {
      //       title: t('app'),
      //       path: paths.dashboard2.root,
      //       icon: ICONS.dashboard,
      //     },
      //     {
      //       title: t('ecommerce'),
      //       path: paths.dashboard2.general.ecommerce,
      //       icon: ICONS.ecommerce,
      //     },
      //     {
      //       title: t('analytics'),
      //       path: paths.dashboard2.general.analytics,
      //       icon: ICONS.analytics,
      //     },
      //     {
      //       title: t('banking'),
      //       path: paths.dashboard2.general.banking,
      //       icon: ICONS.banking,
      //     },
      //     {
      //       title: t('booking'),
      //       path: paths.dashboard2.general.booking,
      //       icon: ICONS.booking,
      //     },
      //     {
      //       title: t('file'),
      //       path: paths.dashboard2.general.file,
      //       icon: ICONS.bill,
      //     },
      //   ],
      // },
      {
        subheader: t('Store '),
        items: [
          {
            title: t('Dashboard'),
            path: paths.dashboard2.root,
            icon: ICONS.Dashboard,
            // children: [
            //   // { title: t('dash'), path: paths.dashboard2.dash.dash },
            //   { title: t('Dashboard'), path: paths.dashboard2.dash.dashapp },
            // ],
          },
          {
            title: t('Product'),
            path: paths.dashboard2.productmaster.root,
            icon: ICONS.Products,

            children: [{ title: t('List'), path: paths.dashboard2.productmaster.productlist }],
          },
          {
            title: t('Supplier'),
            path: paths.dashboard2.supplier.root,
            icon: ICONS.Supplier,
            children: [{ title: t('list'), path: paths.dashboard2.supplier.view }],
          },
          {
            title: t('Purchase'),
            path: paths.dashboard2.purchase.root,
            icon: ICONS.Purchase,
            children: [
              // { title: t('create'), path: paths.dashboard2.purchase.root },
              { title: t('Purchase order'), path: paths.dashboard2.purchase.list },
              { title: t('GRN'), path: paths.dashboard2.purchase.postedgrn },
              { title: t('return'), path: paths.dashboard2.purchase.returnlist },
            ],
          },
          {
            title: t('Inventory'),
            path: paths.dashboard2.inventory.root,
            icon: ICONS.Inventory,
            children: [
              { title: t('product'), path: paths.dashboard2.inventory.root },
              { title: t('Stock'), path: paths.dashboard2.inventory.listall },
            ],
          },
          {
            title: t('Customer'),
            path: paths.dashboard2.customers.root,
            icon: ICONS.Customers,
            children: [
              { title: t('List'), path: paths.dashboard2.customers.master },
              { title: t('Type'), path: paths.dashboard2.customers.typemaster },
              { title: t('Discount'), path: paths.dashboard2.customers.dismaster },
              { title: t('Gift Voucher'), path: paths.dashboard2.customers.giftmaster },
              { title: t('Coupons'), path: paths.dashboard2.customers.coumaster },
            ],
          },

          {
            title: t('Sale'),
            path: paths.dashboard2.sales.root,
            icon: ICONS.Sales,
            children: [
              { title: t('Sales Order'), path: paths.dashboard2.sales.master },
              // { title: t('Delivery List'), path: paths.dashboard2.delivery.root },
            ],
          },
          {
            title: t('Delivery'),
            path: paths.dashboard2.delivery.root,
            icon: ICONS.Delivery,
            children: [{ title: t('List'), path: paths.dashboard2.delivery.root }],
          },
          {
            title: t('User'),
            path: paths.dashboard2.users.root,
            icon: ICONS.Users,
            children: [
              { title: t('Roles'), path: paths.dashboard2.users.role },
              { title: t('Create'), path: paths.dashboard2.users.root },
              { title: t('All'), path: paths.dashboard2.users.overall },
            ],
          },
          {
            title: t('report'),
            path: paths.dashboard2.report.root,
            icon: ICONS.Report,
            children: [{ title: t('Reports'), path: paths.dashboard2.report.reportcreat }],
          },
          {
            title: t('General'),
            path: paths.dashboard2.productmaster.root,
            icon: ICONS.General,

            children: [
              { title: t('Category'), path: paths.dashboard2.productmaster.category },
              { title: t('Subcategory'), path: paths.dashboard2.productmaster.subcat },
              { title: t('Unit'), path: paths.dashboard2.productmaster.unit },
              { title: t('Invoice credit'), path: paths.dashboard2.productmaster.invoicecredit },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
