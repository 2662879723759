import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  customer: icon('ic_cust'),
  bill: icon('ic_bill'),
  delivery: icon('ic_delivery'),
  users: icon('ic_users'),
  purchase: icon('ic_purchase'),
  setting: icon('ic_setting'),
  store: icon('ic_store'),
  Products: icon('ic_file'),
  Users: icon('ic_user'),
  Dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      // {
      //   subheader: t('overview'),
      //   items: [
      //     {
      //       title: t('app'),
      //       path: paths.dashboard.root,
      //       icon: ICONS.dashboard,
      //     },
      //     {
      //       title: t('ecommerce'),
      //       path: paths.dashboard.general.ecommerce,
      //       icon: ICONS.ecommerce,
      //     },
      //     {
      //       title: t('analytics'),
      //       path: paths.dashboard.general.analytics,
      //       icon: ICONS.analytics,
      //     },
      //     {
      //       title: t('banking'),
      //       path: paths.dashboard.general.banking,
      //       icon: ICONS.banking,
      //     },
      //     {
      //       title: t('booking'),
      //       path: paths.dashboard.general.booking,
      //       icon: ICONS.booking,
      //     },
      //     {
      //       title: t('file'),
      //       path: paths.dashboard.general.file,
      //       icon: ICONS.file,
      //     },
      //   ],
      // },

      // MANAGEMENT
      // ----------------------------------------------------------------------

      {
        subheader: t('Super Admin'),
        items: [
          // supplier
          {
            title: t('Dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.Dashboard,
            // children: [
            //   // { title: t('dash'), path: paths.dashboard2.dash.dash },
            //   { title: t('Dashboard'), path: paths.dashboard.root },
            // ],
          },
          {
            title: t('Store'),
            path: paths.dashboard.store.root,
            icon: ICONS.store,
            children: [{ title: t('List'), path: paths.dashboard.store.StoreMaster }],
          },
          {
            title: t('supplier'),
            path: paths.dashboard.supplier.root,
            icon: ICONS.user,
            children: [{ title: t('list'), path: paths.dashboard.supplier.view }],
          },
          {
            title: t('Product'),
            path: paths.dashboard.productmaster.root,
            icon: ICONS.Products,

            children: [{ title: t('List'), path: paths.dashboard.productmaster.productlist }],
          },

          {
            title: t('Purchase'),
            path: paths.dashboard.purchase.list,
            icon: ICONS.purchase,

            children: [
              { title: t('list'), path: paths.dashboard.purchase.list },
              { title: t('GRN'), path: paths.dashboard.purchase.postedgrn },
              { title: t('Return'), path: paths.dashboard.purchase.returnlist },
            ],
          },
          {
            title: t('Inventory'),
            path: paths.dashboard.inventory.root,
            icon: ICONS.analytics,
            children: [
              { title: t('product'), path: paths.dashboard.inventory.root },
              { title: t('Stock'), path: paths.dashboard.inventory.listall },
            ],
          },
          // purchase
          // {
          //   title: t('purchase'),
          //   path: paths.dashboard.purchase.new,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('create'), path: paths.dashboard.purchase.new },
          //     // { title: t('Purchase Order'), path: paths.dashboard.supplier.list },
          //     // { title: t('list'), path: paths.dashboard.user.list },
          //     // { title: t('create'), path: paths.dashboard.user.new },
          //     // { title: t('edit'), path: paths.dashboard.user.demo.edit },
          //     // { title: t('account'), path: paths.dashboard.user.account },
          //   ],
          // },
          // USER
          // {
          //   title: t('user'),
          //   path: paths.dashboard.user.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('profile'), path: paths.dashboard.user.root },
          //     { title: t('cards'), path: paths.dashboard.user.cards },
          //     { title: t('list'), path: paths.dashboard.user.list },
          //     { title: t('create'), path: paths.dashboard.user.new },
          //     { title: t('edit'), path: paths.dashboard.user.demo.edit },
          //     { title: t('account'), path: paths.dashboard.user.account },
          //   ],
          // },
          // GENERAL

          // STORE

          {
            title: t('Customer'),
            path: paths.dashboard.customers.root,
            icon: ICONS.users,
            children: [
              { title: t('List'), path: paths.dashboard.customers.master },
              { title: t('Type'), path: paths.dashboard.customers.typemaster },
              { title: t('Discount'), path: paths.dashboard.customers.dismaster },
              { title: t('Gift Voucher'), path: paths.dashboard.customers.giftmaster },
              { title: t('Coupons'), path: paths.dashboard.customers.coumaster },
            ],
          },

          // {
          //   title: t('Inventory'),
          //   path: paths.dashboard.inventory.root,
          //   icon: ICONS.product,
          //   children: [{ title: t('products'), path: paths.dashboard.inventory.root }],
          // },
          // {
          //   title: t('Users'),
          //   path: paths.dashboard.users.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: t('create'), path: paths.dashboard.users.root },
          //     { title: t('roles'), path: paths.dashboard.users.role },
          //   ],
          // },

          {
            title: t('Sale'),
            path: paths.dashboard.sales.root,
            icon: ICONS.bill,
            children: [{ title: t('Sales Order'), path: paths.dashboard.sales.master }],
          },

          {
            title: t('Delivery'),
            path: paths.dashboard.delivery.root,
            icon: ICONS.delivery,
            children: [{ title: t('List'), path: paths.dashboard.delivery.root }],
          },
          {
            title: t('product Master'),
            path: paths.dashboard.productmaster.root,
            icon: ICONS.ecommerce,

            children: [
              { title: t('category'), path: paths.dashboard.productmaster.category }, // oct 17
              { title: t('subcategory'), path: paths.dashboard.productmaster.subcat }, // oct 17
              { title: t('unit'), path: paths.dashboard.productmaster.unit }, // oct 18
              { title: t('tax'), path: paths.dashboard.productmaster.tax }, // nov 2
              // { title: t('product'), path: paths.dashboard.productmaster.productlist },
            ],
          },
          {
            title: t('User'),
            path: paths.dashboard.users.root,
            icon: ICONS.Users,
            children: [
              // { title: t('Create'), path: paths.dashboard2.users.root },
              // { title: t('Roles'), path: paths.dashboard2.users.role },
              { title: t('All'), path: paths.dashboard.users.overall },
            ],
          },

          {
            title: t('report'),
            path: paths.dashboard.report.root,
            icon: ICONS.analytics,
            children: [{ title: t('Reports'), path: paths.dashboard.report.reportcreat }],
          },
          {
            title: t('general'),
            path: paths.dashboard.generalm.root,
            icon: ICONS.setting,
            children: [
              { title: t('country'), path: paths.dashboard.generalm.country },
              { title: t('state'), path: paths.dashboard.generalm.state },
              { title: t('District'), path: paths.dashboard.generalm.district },
              { title: t('city'), path: paths.dashboard.generalm.city },
            ],
          },
          //   title: t('job'),
          //   path: paths.dashboard.job.root,
          //   icon: ICONS.job,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.job.root },
          //     { title: t('details'), path: paths.dashboard.job.demo.details },
          //     { title: t('create'), path: paths.dashboard.job.new },
          //     { title: t('edit'), path: paths.dashboard.job.demo.edit },
          //   ],
          // },

          // // TOUR
          // {
          //   title: t('tour'),
          //   path: paths.dashboard.tour.root,
          //   icon: ICONS.tour,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.tour.root },
          //     { title: t('details'), path: paths.dashboard.tour.demo.details },
          //     { title: t('create'), path: paths.dashboard.tour.new },
          //     { title: t('edit'), path: paths.dashboard.tour.demo.edit },
          //   ],
          // },

          // // FILE MANAGER
          // {
          //   title: t('file_manager'),
          //   path: paths.dashboard.fileManager,
          //   icon: ICONS.folder,
          // },

          // // MAIL
          // {
          //   title: t('mail'),
          //   path: paths.dashboard.mail,
          //   icon: ICONS.mail,
          //   info: <Label color="error">+32</Label>,
          // },

          // // CHAT
          // {
          //   title: t('chat'),
          //   path: paths.dashboard.chat,
          //   icon: ICONS.chat,
          // },

          // // CALENDAR
          // {
          //   title: t('calendar'),
          //   path: paths.dashboard.calendar,
          //   icon: ICONS.calendar,
          // },

          // // KANBAN
          // {
          //   title: t('kanban'),
          //   path: paths.dashboard.kanban,
          //   icon: ICONS.kanban,
          // },

          // Customers
        ],
      },

      // // DEMO MENU STATES
      // {
      //   subheader: t(t('other_cases')),
      //   items: [
      //     {
      //       // default roles : All roles can see this entry.
      //       // roles: ['user'] Only users can see this item.
      //       // roles: ['admin'] Only admin can see this item.
      //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
      //       // Reference from 'src/guards/RoleBasedGuard'.
      //       title: t('item_by_roles'),
      //       path: paths.dashboard.permission,
      //       icon: ICONS.lock,
      //       roles: ['admin', 'manager'],
      //       caption: t('only_admin_can_see_this_item'),
      //     },
      //     {
      //       title: t('menu_level'),
      //       path: '#/dashboard/menu_level',
      //       icon: ICONS.menuItem,
      //       children: [
      //         {
      //           title: t('menu_level_1a'),
      //           path: '#/dashboard/menu_level/menu_level_1a',
      //         },
      //         {
      //           title: t('menu_level_1b'),
      //           path: '#/dashboard/menu_level/menu_level_1b',
      //           children: [
      //             {
      //               title: t('menu_level_2a'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
      //             },
      //             {
      //               title: t('menu_level_2b'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
      //               children: [
      //                 {
      //                   title: t('menu_level_3a'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
      //                 },
      //                 {
      //                   title: t('menu_level_3b'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       title: t('item_disabled'),
      //       path: '#disabled',
      //       icon: ICONS.disabled,
      //       disabled: true,
      //     },
      //     {
      //       title: t('item_label'),
      //       path: '#label',
      //       icon: ICONS.label,
      //       info: (
      //         <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      //           NEW
      //         </Label>
      //       ),
      //     },
      //     {
      //       title: t('item_caption'),
      //       path: '#caption',
      //       icon: ICONS.menuItem,
      //       caption:
      //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
      //     },
      //     {
      //       title: t('item_external_link'),
      //       path: 'https://www.google.com/',
      //       icon: ICONS.external,
      //     },
      //     {
      //       title: t('blank'),
      //       path: paths.dashboard.blank,
      //       icon: ICONS.blank,
      //     },
      //   ],
      // },
    ],
    [t]
  );

  return data;
}
