import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { HOST_API } from 'src/config-global';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: HOST_API,

    prepareHeaders: (headers, { getState }) => headers,
  }),
  endpoints: () => ({}),
});

