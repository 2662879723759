import { useContext } from 'react';

import { AuthContext } from 'src/auth/context/jwt';

export const Contextid = () => {
  const Auth = useContext(AuthContext);
  const userdata = Auth.user;

  return userdata;
};
