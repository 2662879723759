/* eslint-disable no-plusplus */
import axios, { endpoints } from 'src/utils/axios';

export const Cusdash = async (userdata) => {
  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }
  const random = generateRandomString(10);
  const random1 = generateRandomString(10);
  const random2 = generateRandomString(10);

  try {
    const { admin_id, store_id } = userdata;
    const storeid = store_id || 0;

    const [response1, response2, response3, response4, response5, response6, response7] = await Promise.all([
      axios.get(endpoints.customer.list(admin_id, storeid || 0, random, random1, random2)),
      axios.get(endpoints.product.data(admin_id, storeid || 0)),
      axios.get(endpoints.inventory.data(admin_id, storeid || 0)),
      axios.get(endpoints.sales.salesorderlist(admin_id, storeid || 0)),
      axios.get(endpoints.storeuser.data(admin_id, storeid || 0)),
      axios.get(endpoints.supplier.list(admin_id)),
      axios.put(endpoints.inventory.expense, { admin_id, storeid }),
    ]);
    console.log(response1, response2, response3, response4, response5, response6, response7);
    return {
      response1: response1.data,
      response2: response2.data,
      response3: response3.data,
      response4: response4.data,
      response5: response5.data,
      response6: response6.data,
      response7: response7.data,
    };
  } catch (error) {
    console.error('An error occurred', error);
    return [];
  }
};

export default Cusdash;
