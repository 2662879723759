/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { _roles } from 'src/_mock';

import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import {
  useTable,
  emptyRows,
  TableNoData,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import Excel from 'src/sections/excel/excel';
import { Contextid } from 'src/sections/customers/contex';

import CouTableRow from '../tablerow';
import CouTableToolbar from '../toolbar';
import CouTableFiltersResult from '../tablefilter';
// ----------------------------------------------------------------------

// const TABLE_HEAD1 = [
//   { id: 'name', label: 'Product Name' },

//   { id: 'code', label: 'Product Code' },
//   { id: 'value', label: 'Sales Quantity' },
//   { id: 'status', label: 'Status' },
// ];
const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
  email: '',
  country: '',

  state: '',

  district: '',
  city: '',
  contact: '',
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

export default function CouListView({ TABLE_HEAD, tableData, sname, tableData1, t, name }) {
  const STATUS_OPTIONS = [{ value: 'all', label: `${sname}` }];

  const userdata = Contextid();
  console.log(tableData1);
  const { store_id } = userdata;
  const storeid = store_id || 0;

  const quickEdit = useBoolean();

  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(true);
  const dateError = filters.startDate && filters.endDate ? filters.startDate.getTime() > filters.endDate.getTime() : false;

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
    tableData1,
  });

  const dataInPage = dataFiltered.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage);

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard2.customers.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (tableData1 && tableData) {
      setLoading(false);
    }
  }, [userdata]);
  const handleExport = () => {
    const updatedDataFilteredWithoutInfo = dataFiltered?.map(
      ({ countryInfo, stateInfo, cityInfo, districtInfo, store_id, admin_id, d, ...rest }) => rest
    );

    const filteredHeader = TABLE_HEAD?.filter(
      (item) => item.id !== '' && item.id !== undefined && item.width !== 'excludeWidth' && item.id !== 'excludeWidth'
    ).map(({ width, ...rest }) => rest);

    // console.log('Updated Data Filtered:', updatedDataFiltered);
    // console.log('Data Without Info:', updatedDataFilteredWithoutInfo);
    // console.log('Filtered Header:', filteredHeader);
    const filename = name;
    Excel(updatedDataFilteredWithoutInfo, filteredHeader, filename);
  };
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
          <Card>
            <Tabs
              value={filters.status}
              onChange={handleFilterStatus}
              sx={{
                px: 2.5,
                boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab
                  key={tab.value}
                  iconPosition="end"
                  value={tab.value}
                  label={tab.label}
                  icon={
                    <Label
                      variant={((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'}
                      color={(tab.value === 'Active' && 'success') || (tab.value === 'Inactive' && 'error') || 'default'}
                    >
                      {tab.value === 'all' && tableData.length}
                      {tab.value === 'Active' && tableData.filter((user) => user.status === 'Active').length}

                      {tab.value === 'Inactive' && tableData.filter((user) => user.status === 'Inactive').length}
                    </Label>
                  }
                />
              ))}
            </Tabs>

            <CouTableToolbar filters={filters} onFilters={handleFilters} onExport={handleExport} roleOptions={_roles} dateError={dateError} t={t} />

            {canReset && (
              <CouTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                //
                onResetFilters={handleResetFilters}
                //
                results={dataFiltered.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                // action={
                //   storeid === 0 ? (
                //     ''
                //   ) : (
                //     <Tooltip title="Delete">
                //       <IconButton color="primary" onClick={confirm.onTrue}>
                //         <Iconify icon="solar:trash-bin-trash-bold" />
                //       </IconButton>
                //     </Tooltip>
                //   )
                // }
              />

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    // headLabel={storeid === 0 ? TABLE_HEAD1 : TABLE_HEAD}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    // onSelectAllRows={(checked) =>
                    //   table.onSelectAllRows(
                    //     checked,
                    //     tableData.map((row) => row.id)
                    //   )
                    // }
                  />

                  <TableBody>
                    {dataFiltered.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage).map((row) => (
                      <CouTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        row1={tableData1}
                        t={t}
                      />
                    ))}

                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)} />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters, dateError }) {
  const { name, status, role, startDate, endDate } = filters;
  // console.log(startDate, endDate);
  // console.log(inputData.date);
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter((user) => {
      const lowercaseName = (user.product_name || '' || user.category_code).toLowerCase();
      const lowercaseCode = (user.product_code || '' || user.category_name || user.category_status).toLowerCase();
      const lowercaseValue = (user.total_quantity || '').toString().toLowerCase();

      return (
        lowercaseName.indexOf(name.toLowerCase()) !== -1 ||
        lowercaseCode.indexOf(name.toLowerCase()) !== -1 ||
        lowercaseValue.indexOf(name.toLowerCase()) !== -1
      );
    });
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }
  if (!dateError) {
    if (startDate && endDate) {
      const startTimestamp = new Date(startDate).setHours(0, 0, 0, 0);
      const endTimestamp = new Date(endDate).setHours(23, 59, 59, 999);

      inputData = inputData.filter(
        (invoice) => new Date(invoice.date).getTime() >= startTimestamp && new Date(invoice.date).getTime() <= endTimestamp
      );
    }
  }
  return inputData;
}
