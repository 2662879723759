import { Helmet } from 'react-helmet-async';

// import { AccountView } from 'src/sections/account/view';
import { AccountViewstore } from 'src/sections/store/account/view';
// ----------------------------------------------------------------------

export default function AccountPagestore() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Store Profile</title>
      </Helmet>

      <AccountViewstore />
    </>
  );
}
