import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/use-responsive';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export const METHODS = [
  // {
  //   id: 'jwt',
  //   label: 'Jwt',
  //   path: paths.auth.jwt.login,
  //   icon: '/assets/icons/auth/ic_jwt.svg',
  // },
  // {
  //   id: 'firebase',
  //   label: 'Firebase',
  //   path: paths.auth.firebase.login,
  //   icon: '/assets/icons/auth/ic_firebase.svg',
  // },
  // {
  //   id: 'amplify',
  //   label: 'Amplify',
  //   path: paths.auth.amplify.login,
  //   icon: '/assets/icons/auth/ic_amplify.svg',
  // },
  // {
  //   id: 'auth0',
  //   label: 'Auth0',
  //   path: paths.auth.auth0.login,
  //   icon: '/assets/icons/auth/ic_auth0.svg',
  // },
  {
    id: 'logo',
    label: 'Profile',
    // path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/baytech.svg',
    link: 'https://baytech.in/',
  },
  {
    id: 'linkedin',
    label: 'Linkedin',
    // path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/linkedin.svg',
    link: 'https://www.linkedin.com/company/baytech-in/',
  },
  {
    id: 'insta',
    label: 'Instagram',
    // path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/insta.svg',
    link: 'https://www.instagram.com/baytechinnovations?igsh=MXVpYXgyZWVzeW9rbw==',
  },
  {
    id: 'fb',
    label: 'Facebook',
    // path: paths.auth.auth0.login,
    icon: '/assets/icons/auth/baseline-facebook.svg',
    link: 'https://www.facebook.com/baytech.in',
  },
];

export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  // const renderLogo = (
  //   <Logo
  //     sx={{
  //       zIndex: 9,
  //       position: 'absolute',
  //       m: { xs: 2, md: 5 },
  //     }}
  //   />
  // );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      // sx={{
      //   ...bgGradient({
      //     // color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.88 : 0.94),
      //     imgUrl: '/assets/background/blue.png',
      //   }),
      // }}
      sx={{
        backgroundImage: `url('/assets/background/vv.png')`,
        backgroundSize: 'cover', // Optional: Adjust the background size based on your preference
      }}
    >
      {/* <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
        {title || 'Hi, Welcome back'}
      </Typography> */}

      {/* <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/illustration_dashboard.png'}
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      /> */}

      {/* <Stack direction="row" spacing={2}>
        {METHODS.map((option) => (
          <Tooltip key={option.label} title={option.label}>
            <Link component={RouterLink} href={option.path}>
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{
                  width: 32,
                  height: 32,
                  ...(method !== option.id && {
                    filter: 'grayscale(100%)',
                  }),
                }}
              />
            </Link>
          </Tooltip>
        ))}
      </Stack> */}
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {/* {renderLogo} */}

      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
