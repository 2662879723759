/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import axios, { endpoints } from 'src/utils/axios';

import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';

import { Contextid } from 'src/sections/customers/contex';

import { Cusdash } from '../master/master';
import { CouListView } from '../gift/view';
import AppAreaInstalled from '../app-area-installed';
import AppWidgetSummary from '../app-widget-summary';
import AppCurrentDownload from '../app-current-download';
// ----------------------------------------------------------------------
// const TIME_LABELS = {
//   week: ['Mon', 'Tue', 'Web', 'Thu', 'Fri', 'Sat', 'Sun'],
//   month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//   year: ['2018', '2019', '2020', '2021', '2022', '2018', '2019', '2020'],
// };
export default function OverviewAppViewadmin() {
  const { user } = useMockedUser();
  const userdata = Contextid();
  // console.log(userdata.admin_id);
  const { store_id } = userdata;
  const storeid = store_id || 0;

  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [cat, setcat] = useState([]);
  const [loading, setLoading] = useState(true);

  const [cus, setcus] = useState(0);
  const [sup, setsup] = useState(0);
  const [stock, setstock] = useState(0);
  const [product, setproduct] = useState(0);
  const [cstock, setcstock] = useState(0);
  const [userl, setuser] = useState(0);
  const [chartdata, setchartdata] = useState([]);

  const [paid, setpaid] = useState(0);
  const [retur, setreturn] = useState(0);
  const [cancel, setcancel] = useState(0);
  const [hold, sethold] = useState(0);

  const settings = useSettingsContext();
  const TABLE_HEAD = [
    { id: 'product_name', label: 'Product Name' },

    { id: 'product_code', label: 'Product Code' },
    { id: 'date', label: 'Date' },

    { id: 'quantity', label: 'Sales Quantity' },
    // { id: '', width: 88 },
  ];
  const TABLE_HEAD1 = [
    { id: 'category_code', label: 'Category Code' },

    { id: 'category_name', label: 'Category Name' },

    { id: 'category_status', label: 'Status' },

    { id: 'product_count', label: 'Product Count' },
    // { id: '', width: 88 },
  ];
  const List = async () => {
    try {
      const { admin_id } = userdata;
      const [response1, response2] = await Promise.all([
        axios.get(endpoints.dashboard.getproduct(admin_id, storeid || 0)),
        axios.get(endpoints.dashboard.getproduct1(admin_id, storeid || 0)),
      ]);
      if (response1.data.length > 0) {
        const response1Data = response1.data;
        console.log(response1.data);

        const uniqueEntriesMap = new Map();

        response1Data.forEach((entry) => {
          const key = `${entry.date}-${entry.product_id}`;
          if (uniqueEntriesMap.has(key)) {
            uniqueEntriesMap.get(key).quantity += parseInt(entry.quantity, 10);
          } else {
            uniqueEntriesMap.set(key, { ...entry, quantity: parseInt(entry.quantity, 10) });
          }
        });

        const processedData = Array.from(uniqueEntriesMap.values());

        console.log(processedData);
        setTableData(processedData);
      } else {
        setTableData([]);
      }

      if (response2.data.length > 0) {
        setTableData1(response2.data);
        console.log(response2.data);
      } else {
        setTableData1([]);
      }
    } catch (error) {
      console.error('An error occurred', error);
    }
  };
  const List1 = async () => {
    try {
      const { admin_id } = userdata;
      const [response1] = await Promise.all([axios.get(endpoints.dashboard.getcat(admin_id, storeid || 0))]);
      if (response1.data.length > 0) {
        // const response1Data = response1.data;
        console.log('dddd', response1.data);
        setcat(response1.data);
      } else {
        setcat([]);
      }
    } catch (error) {
      console.error('An error occurred', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await List();
      await List1();
      const { response1, response2, response3, response4, response5, response6, response7 } = await Cusdash(userdata);
      if (response1?.length > 0) {
        setcus(response1?.length);
      }
      if (response6?.length > 0) {
        setsup(response6?.length);
      }
      if (response3?.length > 0 || response4?.length > 0 || response2?.length > 0 || response5?.length > 0) {
        setproduct(response2?.length);
        setuser(response5?.length);

        let totalCStock = 0;
        for (const item of response3) {
          totalCStock += parseInt(item.c_stock, 10);
        }
        setstock(totalCStock);

        let totalCStockInactive = 0;
        for (const item of response3) {
          if (item.product_status_inv === 'Active') {
            totalCStockInactive += parseInt(item.c_stock, 10);
          }
        }
        setcstock(totalCStockInactive);

        let paidCount = 0;
        let cancelCount = 0;
        let returncount = 0;
        let holdcount = 0;

        response4.forEach((order) => {
          if (order.status === 'paid') {
            paidCount++;
          } else if (order.status === 'cancel') {
            cancelCount++;
          } else if (order.status === 'returned') {
            returncount++;
          } else if (order.status === 'hold') {
            holdcount++;
          }
        });

        sethold(holdcount);
        setreturn(returncount);
        setpaid(paidCount);
        setcancel(cancelCount);
        setLoading(false);

        // if (response4 || response7) {
        //   const uniqueYears = Array.from(new Set(response4.map((entry) => new Date(entry.date).getFullYear())));
        //   const uniqueYears1 = Array.from(new Set(response7.map((entry) => new Date(entry.date).getFullYear())));

        //   const chartData = {
        //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        //     series: uniqueYears.map((year) => {
        //       const yearData = response4.filter((entry) => new Date(entry.date).getFullYear() === year);
        //       const incomeData = Array(12).fill(0);
        //       const expenseData = Array(12).fill(0);
        //       const yearData1 = response7.filter((entry) => new Date(entry.date).getFullYear() === year);

        //       yearData.forEach((entry) => {
        //         if ('total_amount' in entry) {
        //           const month = new Date(entry.date).getMonth();
        //           const totalAmount = parseFloat(entry.total_amount) || 0;

        //           if (entry.status === 'paid') {
        //             incomeData[month] += totalAmount;
        //           } else if (entry.status === 'returned') {
        //             incomeData[month] -= totalAmount;
        //           }
        //         }
        //       });
        //       yearData1.forEach((entry) => {
        //         if ('total_amount1' in entry) {
        //           const month = new Date(entry.date).getMonth();
        //           const totalAmount1 = parseFloat(entry.total_amount1) || 0;

        //           expenseData[month] += totalAmount1;
        //         }
        //       });

        //       return {
        //         year: year.toString(),
        //         data: [
        //           { name: 'Expense', data: expenseData.map((value) => value.toFixed(2)) },
        //           { name: 'Income', data: incomeData.map((value) => value.toFixed(2)) },
        //         ],
        //       };
        //     }),
        //   };

        //   setchartdata(chartData);
        // }
        if (response4 || response7) {
          const uniqueYears = Array.from(new Set([...response4, ...response7].map((entry) => new Date(entry.date).getFullYear())));
          const chartData = {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            series: uniqueYears.map((year) => {
              const yearData = response4.filter((entry) => new Date(entry.date).getFullYear() === year);
              const incomeData = Array(12).fill(0);

              yearData.forEach((entry) => {
                if ('total_amount' in entry) {
                  const month = new Date(entry.date).getMonth();
                  const totalAmount = parseFloat(entry.total_amount) || 0;

                  if (entry.status === 'paid') {
                    incomeData[month] += totalAmount;
                  } else if (entry.status === 'returned') {
                    incomeData[month] -= totalAmount;
                  }
                }
              });

              const expenseData = Array(12).fill(0);
              const yearData1 = response7.filter((entry) => new Date(entry.date).getFullYear() === year);
              yearData1.forEach((entry) => {
                if ('purchase_rate' in entry) {
                  const month = new Date(entry.date).getMonth();
                  const totalAmount1 = parseFloat(entry.purchase_rate) || 0;

                  expenseData[month] += totalAmount1;
                }
              });
              return {
                year: year.toString(),
                data: [
                  { name: 'Expense', data: expenseData.map((value) => value.toFixed(2)) },
                  { name: 'Income', data: incomeData.map((value) => value.toFixed(2)) },
                ],
              };
            }),
          };

          setchartdata(chartData);
        }
      } else {
        setLoading(false);
      }
    };

    // console.log(paid, retur, hold, cancel);

    fetchData();
    // setLoading(false);
  }, [userdata]);

  return (
    <>
      {' '}
      {loading ? (
        <LoadingScreen />
      ) : (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
          {loading ? (
            <LoadingScreen />
          ) : (
            <Grid container spacing={3}>
              <Grid xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Customers"
                  percent={2.6}
                  total={cus}
                  chart={{
                    series: [5, 18, 12, 51, 68, 11, 39, 12, 51, 68, 11, 39],
                  }}
                />
              </Grid>

              <Grid xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Supliers"
                  percent={0.2}
                  total={sup}
                  chart={{
                    colors: [theme.palette.info.light, theme.palette.info.main],
                    series: [5, 18, 12, 51, 68, 11, 39, 12, 51, 68, 11, 39],
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Users"
                  percent={-0.1}
                  total={userl}
                  chart={{
                    colors: [theme.palette.warning.light, theme.palette.warning.main],
                    series: [5, 18, 12, 51, 68, 11, 39, 12, 51, 68, 11, 39],
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Products"
                  percent={-0.1}
                  total={product}
                  chart={{
                    colors: [theme.palette.warning.light, theme.palette.warning.main],
                    series: [5, 18, 12, 51, 68, 11, 39, 12, 51, 68, 11, 39],
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Stock"
                  percent={-0.1}
                  total={stock}
                  chart={{
                    colors: [theme.palette.info.light, theme.palette.info.main],
                    series: [5, 18, 12, 51, 68, 11, 39, 12, 51, 68, 11, 39],
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Assigned Stock"
                  percent={-0.1}
                  total={cstock}
                  chart={{
                    // colors: [theme.palette.warning.light, theme.palette.warning.main],
                    series: [5, 18, 12, 51, 68, 11, 39, 12, 51, 68, 11, 39],
                  }}
                />
              </Grid>

              <Grid xs={12} md={6} lg={4}>
                <AppCurrentDownload
                  title="Total Sale Order"
                  chart={{
                    series: [
                      { label: 'Paid', value: paid },
                      { label: 'Returned', value: retur },
                      { label: 'Hold', value: hold },
                      { label: 'Cancel', value: cancel },
                    ],
                  }}
                />
              </Grid>

              <Grid xs={12} md={6} lg={8}>
                <AppAreaInstalled title="Income & Expense" chart={chartdata} />
              </Grid>
              {/* <Grid xs={12} md={12} lg={12}>
                <FileDataActivity
                  title="Data Activity"
                  chart={{
                    labels: TIME_LABELS,
                    colors: [theme.palette.primary.main, theme.palette.error.main, theme.palette.warning.main, theme.palette.text.disabled],
                    series: [
                      {
                        type: 'Week',
                        data: [
                          { name: 'Images', data: [20, 34, 48, 65, 37, 48, 9] },
                          { name: 'Media', data: [10, 34, 13, 26, 27, 28, 18] },
                          { name: 'Documents', data: [10, 14, 13, 16, 17, 18, 28] },
                          { name: 'Other', data: [5, 12, 6, 7, 8, 9, 48] },
                        ],
                      },
                      {
                        type: 'Month',
                        data: [
                          {
                            name: 'Images',
                            data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 12, 43, 34],
                          },
                          {
                            name: 'Media',
                            data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 12, 43, 34],
                          },
                          {
                            name: 'Documents',
                            data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 12, 43, 34],
                          },
                          {
                            name: 'Other',
                            data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 12, 43, 34],
                          },
                        ],
                      },
                      {
                        type: 'Year',
                        data: [
                          { name: 'Images', data: [10, 34, 13, 56, 77] },
                          { name: 'Media', data: [10, 34, 13, 56, 77] },
                          { name: 'Documents', data: [10, 34, 13, 56, 77] },
                          { name: 'Other', data: [10, 34, 13, 56, 77] },
                        ],
                      },
                    ],
                  }}
                />
              </Grid> */}
              {/* <Grid xs={12} md={12} lg={12}>
                <CouListView TABLE_HEAD={TABLE_HEAD} tableData={tableData} sname="Sold quantity" tableData1={tableData1} />
              </Grid> */}
              <Grid xs={12} md={12} lg={12}>
                <CouListView TABLE_HEAD={TABLE_HEAD1} tableData={cat} sname="Category" tableData1={cat} t={2} name="Category-List.xlsx" />
              </Grid>
            </Grid>
          )}
        </Container>
      )}
    </>
  );
}
