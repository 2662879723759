// oct 18
import PropTypes from 'prop-types';

import Button from '@mui/material/Button'; // oct 19
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function NewDialog({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      {/* <DialogTitle sx={{ pb: 2 }}> // oct 19
        {title}
        </DialogTitle> */}

      <DialogTitle sx={{ pb: 2, display: 'flex', justifyContent: 'space-between' }}>
        {title}
        <Button onClick={onClose}>
          <Iconify icon="iconoir:cancel" />
        </Button>
      </DialogTitle>

      {content && (
        <DialogContent
          sx={{
            typography: 'body2',
            // overflowY: 'hidden',
          }}
        >
          {content}
        </DialogContent>
      )}

      <DialogActions>{action}</DialogActions>
    </Dialog>
  );
}

NewDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
