import { paths } from 'src/routes/paths';

// import  from 'src/utils/axios';
import axios, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();
  // console.log(currentTime);
  // console.log(exp);

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  // console.log(timeLeft);
  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

export const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    // if (refreshToken) localStorage.setItem('refreshToken', refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};
export const setSessionstore = (accessToken, userid) => {
  // console.log('setSessionstore - Start:', userid);

  if (accessToken && userid) {
    sessionStorage.setItem('accessTokenstore', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);

    // console.log('setSessionstore - End:', userid);

    // Start periodic token checks
    checkTokenEveryThreeSeconds(userid);
  } else {
    // console.log('setSessionstore - Missing accessToken or userid:', accessToken, userid);
    sessionStorage.removeItem('accessTokenstore');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const checkTokenEveryThreeSeconds = (initialUserId) => {
  // console.log('checkTokenEveryThreeSeconds - Start:', initialUserId);

  const intervalId = setInterval(() => {
    const checkToken = async () => {
      try {
        const currentUserId = initialUserId; // Capture the current value

        // console.log('currentUserId', currentUserId);

        const accessTokenstore = sessionStorage.getItem('accessTokenstore');
        // console.log('checkTokenEveryThreeSeconds - Stored Access Token:', accessTokenstore);

        const response1 = await axios.post(endpoints.storeuser.check, { userid: currentUserId });
        const tokendata = response1?.data?.data[0]?.token;

        // console.log('checkTokenEveryThreeSeconds - Received Token:', tokendata);

        if (response1.status === 200 && accessTokenstore !== null) {
          if (tokendata !== accessTokenstore) {
            alert('Token not found. Logging out.');
            sessionStorage.removeItem('accessTokenstore');
            window.location.href = paths.auth.jwt.register;
          }
        }
      } catch (error) {
        console.error('checkTokenEveryThreeSeconds - Error checking token:', error);
      }
    };

    checkToken();
  }, 5000);

  // console.log('checkTokenEveryThreeSeconds - End:', initialUserId);

  // Return the intervalId to allow clearing the interval if needed
  return intervalId;
};
