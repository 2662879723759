/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const getFilteredData = (data, header) => {
  const filteredData = data.map((item) => {
    const filteredItem = {};

    header.forEach((column) => {
      if (column) {
        const { id } = column;
        filteredItem[id] = item[id];
      }
    });

    return filteredItem;
  });

  return filteredData;
};

const Excel = async (data, header, filename) => {
  // console.log(data);

  // console.log(data.length);

  const filteredData = getFilteredData(data, header);
  // console.log(filteredData);

  // Check if transformedData is not empty
  const transformedData =
    filteredData.length > 0
      ? filteredData.map((obj) => {
          const transformedObj = {};
          for (const key in obj) {
            const transformedKey = key.toUpperCase().replace(/_/g, ' ');
            transformedObj[transformedKey] = obj[key];
          }
          return transformedObj || [];
        })
      : [];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Check if transformedData is not empty before accessing its first element
  if (transformedData.length > 0) {
    const headerRow = worksheet.addRow(Object.keys(transformedData[0]));
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
        bgColor: { argb: 'FFFFFF' },
      };
      cell.font = {
        color: { argb: 'FFFFFF' },
      };
    });

    transformedData.forEach((rowData) => {
      worksheet.addRow(Object.values(rowData));
    });

    const blob = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), filename);
  }
};

export default Excel;
