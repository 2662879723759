/* eslint-disable react-hooks/exhaustive-deps */

import { useRef, useMemo, useState, useEffect } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import { useTranslate } from 'src/locales';

import { Contextid } from 'src/sections/customers/type/customers copy/contex';

import { Data, ICONS } from './datas-nav';
// ----------------------------------------------------------------------
// export function useNavData() {
//   const { t } = useTranslate();

//   const data = useMemo(
//     () => [
//       {
//         subheader: t('Sales'),
//         items: [
//           // purchase
//           {
//             title: t('purchase'),
//             path: paths.dashboard2.purchase.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('create'), path: paths.dashboard2.purchase.root },
//               { title: t('list'), path: paths.dashboard2.purchase.list },
//               { title: t('GRN'), path: paths.dashboard2.purchase.postedgrn },
//             ],
//           },

//           // Customers
//           {
//             title: t('Customers'),
//             path: paths.dashboard2.customers.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('Customer List'), path: paths.dashboard2.customers.master }, // oct 17
//               { title: t('Customer Type'), path: paths.dashboard2.customers.typemaster }, // oct 17
//               { title: t('Discount'), path: paths.dashboard2.customers.dismaster }, // oct 17
//               { title: t('Gift Voucher'), path: paths.dashboard2.customers.giftmaster }, // oct 18
//               { title: t('Coupons'), path: paths.dashboard2.customers.coumaster }, // oct 18
//             ],
//           },

//           {
//             title: t('Productmaster'),
//             path: paths.dashboard2.productmaster.root,
//             icon: ICONS.user,
//             children: [{ title: t('list'), path: paths.dashboard2.productmaster.productlist }],
//           },
//           {
//             title: t('Inventory'),
//             path: paths.dashboard2.inventory.root,
//             icon: ICONS.product,
//             children: [{ title: t('products'), path: paths.dashboard2.inventory.root }],
//           },
//           {
//             title: t('Users'),
//             path: paths.dashboard2.users.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('create'), path: paths.dashboard2.users.root },
//               { title: t('roles'), path: paths.dashboard2.users.role },
//             ],
//           },
//           {
//             title: t('Sales'),
//             path: paths.dashboard2.sales.root,
//             icon: ICONS.invoice,
//             children: [{ title: t('Sales Order'), path: paths.dashboard2.sales.master }],
//           },
//         ],
//       },
//     ],
//     [t]
//   );

//   return data;
// }

export function useNavData() {
  const originalDataRef = useRef(Data());
  const originalData = originalDataRef.current;
  const [navData, setNavData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const userdata = Contextid();
  const { admin_id, store_id } = userdata;
  const { t } = useTranslate();
  const { roleData } = userdata;
  const transformDataStructure = useMemo(
    () => (data) => {
      const transformedData = {};

      data.forEach((item) => {
        if (item.c_title && item.c_path) {
          const childItem = {
            title: item.c_title,
            path: item.c_path,
          };

          if (transformedData[item.p_title]) {
            transformedData[item.p_title].children.push(childItem);
          } else {
            transformedData[item.p_title] = {
              title: item.p_title,
              path: item.p_path,
              icon: item.p_title,
              children: [childItem],
            };
          }
        } else {
          transformedData[item.p_title] = {
            title: item.p_title,
            path: item.p_path,
            icon: item.p_title,
            children: [],
          };
        }
      });

      return Object.values(transformedData);
    },
    []
  );

  const getData = useMemo(
    () => async (id) => {
      if (!id) {
        return;
      }

      try {
        const response = await axios.get(endpoints.nav.data(admin_id, store_id, id));
        const transformedData = transformDataStructure(response.data);
        setUpdatedData(transformedData);
      } catch (error) {
        console.error('Error making API call:', error);
      }
    },
    [admin_id, store_id, transformDataStructure]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (roleData) {
        await getData(roleData.role_id);
      }
    };

    fetchData();
  }, [getData, roleData]);
  // useEffect(() => {
  //   if (roleData) {
  //     setNavData([
  //       {
  //         subheader: t('User Dashboard'),
  //         items: updatedData.map((item) => ({
  //           title: t(item.title),
  //           path: item.path,
  //           children: item.children.map((child) => ({ title: t(child.title), path: child.path })),
  //         })),
  //       },
  //     ]);
  //   } else {
  //     setNavData(originalData);
  //   }
  // }, [t, roleData, updatedData]);
  useEffect(() => {
    if (roleData) {
      setNavData([
        {
          subheader: t('User Dashboard'),
          items: updatedData.map((item) => {
            const baseItem = {
              title: t(item.title),
              path: item.path,
              icon: ICONS[item.icon],
            };

            // Check if item.children is available
            if (item.children && item.children.length > 0) {
              // If available, include children
              return {
                ...baseItem,
                children: item.children.map((child) => ({
                  title: t(child.title),
                  path: child.path,
                })),
              };
            }
            // If not available, return baseItem without children
            return baseItem;
          }),
        },
      ]);
    } else {
      setNavData(originalData);
    }
  }, [t, roleData, updatedData]);

  return navData;
}
