/* eslint-disable react/prop-types */
import io from 'socket.io-client';
import { useState, useEffect, useContext, createContext } from 'react';

import { HOST_API } from './config-global';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(`${HOST_API}/socket.io`);

    setSocket(newSocket);

    return () => {
      // Cleanup WebSocket connection on component unmount
      newSocket.disconnect();
    };
  }, []);

  return <WebSocketContext.Provider value={socket}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => {
  const socket = useContext(WebSocketContext);
  if (!socket) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return socket;
};
