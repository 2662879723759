/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { Contextid } from 'src/sections/customers/contex';

export default function CouTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, List, row1, t }) {
  const userdata = Contextid();
  const { store_id } = userdata;
  const storeid = store_id || 0;
  const collapse = useBoolean();
  console.log(row1);
  console.log(row);
  console.log(t);

  const { quantity, product_code, product_name, date, category_code, category_name, category_status, product_count } = row;
  const [filteredSecondaryData, setFilteredSecondaryData] = useState([]);
  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();
  const renderPrimary = (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{product_name}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{product_code}</TableCell>
      <TableCell>
        <Label variant="soft" color="warning">
          {fDate(date)}
        </Label>
      </TableCell>
      <TableCell>
        <Label variant="soft" color="success">
          {quantity}
        </Label>
      </TableCell>
      {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={() => {
            filterSecondaryData();
            collapse.onToggle();
          }}
          sx={{
            ...(collapse.value && {
              bgcolor: 'action.hover',
            }),
          }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
  const renderPrimary2 = (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{category_code}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{category_name}</TableCell>
      <TableCell>
        <Label variant="soft" color="warning">
          {category_status}
        </Label>
      </TableCell>
      <TableCell>
        <Label variant="soft" color="success">
          {product_count}
        </Label>
      </TableCell>
      {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={() => {
            filterSecondaryData();
            collapse.onToggle();
          }}
          sx={{
            ...(collapse.value && {
              bgcolor: 'action.hover',
            }),
          }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
  // ...
  const filterSecondaryData = () => {
    const filteredData = row1.filter((item) => item.product_id === row.product_id);
    setFilteredSecondaryData(filteredData);
  };
  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={6}>
        <Collapse in={collapse.value} timeout="auto" unmountOnExit sx={{ bgcolor: 'background.neutral' }}>
          <Stack component={Paper} sx={{ m: 1.5 }}>
            {/* Secondary Header */}
            <Stack
              direction="row"
              alignItems="center"
              sx={{ p: (theme) => theme.spacing(1, 2, 1, 1), borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}` }}
            >
              <Box flexGrow={1} />
              <Typography variant="body2">Date</Typography>
              <Typography variant="body2" sx={{ flexBasis: 110, textAlign: 'right' }}>
                Sold Quantity
              </Typography>
            </Stack>
            <Scrollbar style={{ height: 500 }}>
              {filteredSecondaryData.map((item) => (
                <Stack
                  key={item.id}
                  direction="row"
                  alignItems="center"
                  sx={{
                    p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                    '&:not(:last-of-type)': {
                      borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                    },
                  }}
                >
                  {/* Product Details */}
                  <ListItemText
                    primary={item.product_name}
                    secondary={item.product_code}
                    primaryTypographyProps={{
                      typography: 'body2',
                    }}
                    secondaryTypographyProps={{
                      component: 'span',
                      color: 'text.disabled',
                      mt: 0.5,
                    }}
                  />

                  <Box>
                    {' '}
                    <Label variant="soft" color="warning">
                      {fDate(item.date)}
                    </Label>
                  </Box>

                  <Box sx={{ width: 110, textAlign: 'right' }}>
                    {' '}
                    <Label variant="soft" color="success">
                      {item.total_quantity}
                    </Label>
                  </Box>
                </Stack>
              ))}
            </Scrollbar>
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  // ...

  return (
    <>
      {t === 2 ? renderPrimary2 : renderPrimary}

      {renderSecondary}

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

CouTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  List: PropTypes.func,
  row1: PropTypes.object,
  t: PropTypes.object,
};
