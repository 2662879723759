import { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';
import store from './Service/Provider/provider';
import { WebSocketProvider } from './WebSocketContext';
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <WebSocketProvider>
          <App />
        </WebSocketProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
  </Provider>
);
